import { IonButton, IonImg } from "@ionic/react";
import React from "react";
import EmptyComment from "../../../assets/images/icons/empty-comment-icon.svg";
import LanguageAppText from "../../languageapptext";
import "./style.scss";

const EmptyCommentList: React.FC = () => {
  return (
    <div className="empty-comment__container">
      <IonImg src={EmptyComment} className="empty-comment__icon mb-5" />
      <LanguageAppText
        tag={"div"}
        className="empty-comment__title text-blue font-medium mb-10"
        value={"Be the first person to add a comment!"}
        textKey={"first_to_comment"}
      />
      <LanguageAppText
        tag={"div"}
        className="empty-comment__desc text-gray"
        value={"You can leave comments via text, audio and video!"}
        textKey={"leave_comment_type"}
      />
      <div className=""></div>
    </div>
  )
}
export default EmptyCommentList