import React, { useState } from "react";
import "./style.scss";
import Like from "../../assets/images/icons/ic_like.svg";
import LikeWhite from "../../assets/images/icons/ic_like_orange.svg";
import { IonImg } from "@ionic/react";
import { formatNumber } from "../../utils/common.helper";
//import { formatNumber } from '../../utils/helper'

interface Props {
  likes: number;
  isCommentLiked: boolean;
  onClick?: () => Promise<boolean>;
}

const Likes = (props: Props) => {
  const { likes, isCommentLiked, onClick } = props;
  const [isLiked, setIsLiked] = useState(isCommentLiked);
  const [isClick, setIsClick] = useState(false);
  const onPress = async () => {
    if (isClick) return;
    setIsClick(true);
    if (onClick) {
      setIsLiked(!isLiked);
      const res = await onClick();
      if (!res) {
        setIsLiked(!isLiked);
      }
    }
    setIsClick(false);
  };
  return (
    <div className="like-container" onClick={onPress}>
      <div className="like__image">
        {!isLiked ? <IonImg src={Like} /> : <IonImg src={LikeWhite} />}
      </div>
      <div className="likes__count">
        {formatNumber(Math.max(likes || 0, 0))}
      </div>
    </div>
  );
};
export default Likes;
