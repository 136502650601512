import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonPage,
  IonRippleEffect,
} from "@ionic/react";
import { triangle, ellipse, square } from "ionicons/icons";
import React, { useState } from "react";
import { Route } from "react-router";
import Experts from "./experts";
import Home from "./home";
import Rewards from "./rewards";
import Stories from "./stories";
import Tools from "./tools";
import "./style.scss";
import LanguageAppText from "../../components/languageapptext";

const Tabs: React.FC = () => {
  const [storiesTab, setstoriesTab] = useState(
    window.location.pathname == "/tabs/stories"
  );
  const [tabNotification, settabNotification] = useState(false);

  //console.log(window.location.pathname);
  const tabsClick = (e: { detail: { tab: string } }) => {
    if (e.detail.tab === "stories") {
      setstoriesTab(true);
    } else if (e.detail.tab != "stories") {
      setstoriesTab(false);
    }
  };

  return (
    <IonPage>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/tabs/:tab(experts)">
            <Experts />
          </Route>
          <Route path="/tabs/:tab(tools)">
            <Tools />
          </Route>
          <Route path="/tabs/:tab(home)">
            <Home />
          </Route>
          <Route path="/tabs/:tab(stories)">
            <Stories />
          </Route>
          <Route path="/tabs/:tab(rewards)">
            <Rewards />
          </Route>
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          className={`bottom-nav ${storiesTab ? "stories" : "experts"}`}
          onIonTabsWillChange={tabsClick}
        >
          <IonTabButton
            className="ion-tab__btn experts"
            tab="experts"
            href="/tabs/experts"
          >
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Experts"}
              textKey={"experts"}
            />

            {tabNotification && <div className="ion-tab__notification"></div>}
          </IonTabButton>
          <IonTabButton
            className="ion-tab__btn tools"
            tab="tools"
            href="/tabs/tools"
          >
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Tools"}
              textKey={"tools"}
            />
            {tabNotification && <div className="ion-tab__notification"></div>}
          </IonTabButton>
          <IonTabButton
            className="ion-tab__btn home"
            tab="home"
            href="/tabs/home"
          >
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Home"}
              textKey={"home"}
            />
            {tabNotification && <div className="ion-tab__notification"></div>}
          </IonTabButton>
          <IonTabButton
            className="ion-tab__btn stories"
            tab="stories"
            href="/tabs/stories"
          >
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Stories"}
              textKey={"stories"}
            />
            {tabNotification && <div className="ion-tab__notification"></div>}
          </IonTabButton>
          <IonTabButton
            className="ion-tab__btn rewards"
            tab="rewards"
            href="/tabs/rewards"
          >
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Rewards"}
              textKey={"rewards"}
            />
            {tabNotification && <div className="ion-tab__notification"></div>}
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonPage>
  );
};

export default Tabs;
