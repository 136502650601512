import { IonButton, IonImg, IonThumbnail } from "@ionic/react";
import React, { useEffect, useState } from "react";
import AudioPlayer from "../../audioplayer";
import ProfileImage from "../../common/profileimg";
import Divider from "../../divider";
import Likes from "../../likeview";
import ThumbnailVideo from "../../../assets/images/global-default_new.jpeg";
import PlayStory from "../../../assets/images/icons/play-story.svg";
import "./style.scss";
import { getFormattedUrl } from "../../../utils/common.helper";
import Popup from "../../common/popup";
import ButtonComponent from "../../common/button-component";
import LanguageAppText from "../../languageapptext";
import { useHistory } from "react-router";

interface Props {
  data?: any;
  showEdit?: boolean;
  showDelete?: boolean;
  onEdit?: (id: number, text: string) => void;
  onVidoePlay?: (videoUrl: string, thumbUrl: string) => void;
  onLikePress?: (id: number) => Promise<any>;
  onDeletePress?: (id: number) => void;
}
let isShowDetail = false;
const CommentCard: React.FC<Props> = ({
  data,
  showEdit,
  showDelete,
  onEdit,
  onVidoePlay,
  onLikePress,
  onDeletePress,
}) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  useEffect(() => {
    // document.addEventListener("ionBackButton", (ev) => {
    //   handleBack(ev);
    // });
    // return () => {
    //   document.removeEventListener("ionBackButton", (ev) => {
    //     handleBack(ev);
    //   });
    //   // isShowDetail = false;
    // };
  });

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isShowDetail) {
        setShowModal(false);
        isShowDetail = false;
      } else {
        history.goBack();
      }
    });
  };

  const onCommentLike = async (id: number) => {
    const resp = onLikePress && (await onLikePress(id));
    return resp;
  };

  return (
    <div className="comment-container">
      <div className="userprofile-container mb-5">
        <div className="profile-container">
          <ProfileImage
            imgPath={
              data.user?.profile?.profilePic
                ? getFormattedUrl(data.user?.profile?.profilePic?.uri || "")
                : undefined
            }
          />
          <div className="userprofile-name text-orange font-medium ml-10">
            {data.user?.name || "Anonymous"}
          </div>
        </div>
        <div className="comment-button__container">
          {showDelete && (
            <div
              className="comment-button__delete text-light-black font-medium mr-20"
              onClick={() => {
                isShowDetail = true;
                setShowModal(true);
              }}
            >
              <LanguageAppText
                tag={"span"}
                value={"Delete"}
                textKey={"delete"}
              />
            </div>
          )}
          {showEdit && (
            <div
              className="comment-button__edit text-light-black font-medium mr-20"
              onClick={() => {
                if (onEdit) {
                  onEdit(data.id, data.text || "");
                }
              }}
            >
              <LanguageAppText tag={"span"} value={"Edit"} textKey={"edit"} />
            </div>
          )}
          {data.status === "approved" && (
            <Likes
              isCommentLiked={data.isLiked}
              likes={data.likeCount}
              onClick={async () => {
                if (onLikePress) {
                  return await onCommentLike(data.id);
                }
              }}
            ></Likes>
          )}
        </div>
      </div>
      <div className="comment-type-container">
        {data.commentType == "text" && (
          <div className="comment-type-text text-black">{data.text}</div>
        )}
        {data.commentType == "video" && (
          <div
            className="comment-type-video"
            onClick={() => {
              if (onVidoePlay) {
                onVidoePlay(data.video?.uri, data.thumbnail?.uri || "");
              }
            }}
          >
            <div className="page-stories__thumbnail-container">
              <IonThumbnail slot="start" className="page-stories__thumbnail">
                <img
                  className=""
                  src={
                    data.thumbnail?.uri
                      ? getFormattedUrl(data.thumbnail?.uri)
                      : ThumbnailVideo
                  }
                />
              </IonThumbnail>
              {/* <IonImg
                src={
                  data.thumbnail?.uri
                    ? getFormattedUrl(data.thumbnail?.uri)
                    : ThumbnailVideo
                }
                className="page-stories__thumbnail"
              /> */}
              <IonButton className="page-stories__play ion-button__transparent-with-icon">
                <IonImg src={PlayStory} />
              </IonButton>
            </div>
          </div>
        )}
        {data.commentType == "audio" && (
          <div className="comment-type-audio" style={{ width: 70 }}>
            <AudioPlayer filePath={getFormattedUrl(data.audio?.uri || "")} />
          </div>
        )}
        <Divider />
      </div>

      {showModal && (
        <Popup
          customClass="delete-comment-popup"
          onClose={() => {
            setShowModal(false);
            isShowDetail = false;
          }}
        >
          <div className="delete-popup-container">
            <LanguageAppText
              tag="div"
              className={"delete-popup-text text-light-black font-medium mb-20"}
              textKey={"delete_comment"}
              value="Are you sure that you want to delete this comment?"
            />

            <div className="delete-popup-button-wrapper">
              <ButtonComponent
                name="Yes"
                textKey={"yes"}
                onClick={() => {
                  if (onDeletePress) {
                    onDeletePress(data.id);
                  }
                  setShowModal(false);
                  isShowDetail = false;
                }}
                customClass="delete-popup-button mr-10"
              ></ButtonComponent>
              <ButtonComponent
                name="No"
                textKey={"no"}
                outline
                onClick={() => {
                  setShowModal(false);
                  isShowDetail = false;
                }}
                customClass="delete-popup-button ml-10"
              ></ButtonComponent>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};
export default CommentCard;
