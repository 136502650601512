import { IonButton, IonImg, IonItemDivider, IonThumbnail } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./style.scss";
import DefaultHomeCardImg from "../../assets/images/global-default_new.jpeg";
import TitlePolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import Likes from "../likeview";
import LanguageText from "../languagetext";
import {
  formatStringToJSON,
  notificationRedirect,
} from "../../utils/common.helper";

interface Props {
  titleText?: string;
  customClass?: string;
  imgPath?: string;
  storyCard?: boolean;
  notificationCard?: boolean;
  oldNotification?: boolean;
  descriptionText?: string;
  notificationDays?: string;
  likeCount?: any;
  metaData?: any;
  isClickable?: boolean;
}

const HomeStoryCard: React.FC<Props> = ({
  titleText,
  customClass,
  imgPath,
  storyCard,
  notificationCard,
  oldNotification,
  descriptionText,
  notificationDays,
  likeCount,
  metaData,
  isClickable,
}) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  const handleCardClick = () => {
    if (isClickable) {
      const parsedData = formatStringToJSON(metaData);
      notificationRedirect(parsedData);
    }
  };

  return (
    <div
      className={`home-story-card__container mt-10 bg-white ${
        storyCard ? "story__container" : "notification__container"
      } ${customClass || ""}`}
      onClick={handleCardClick}
    >
      {storyCard && (
        <div className="home-story-card-thumbnail mr-10">
          <IonThumbnail
            slot="start"
            className="home-story-card-thumbnail-container"
          >
            <img
              className="home-story-card-thumbnail-img"
              src={mediaPath || DefaultHomeCardImg}
            />
          </IonThumbnail>
        </div>
        // <IonImg src={mediaPath || DefaultHomeCardImg} className="story-img mr-10" />
      )}
      <div className="story-card__details">
        <LanguageText
          tag={"div"}
          className="story-detail__title text-blue font-medium"
          value={titleText}
        />
        <LanguageText
          tag={"div"}
          className="story-detail__description text-light-black font-medium mt-5 mb-8"
          value={descriptionText}
        />
        {storyCard && <Likes isCommentLiked={false} likes={likeCount}></Likes>}
        {!storyCard && notificationCard && oldNotification && (
          <div className="story-detail__days text-black font-medium mt-5">
            {notificationDays}
          </div>
        )}
      </div>

      {!storyCard && notificationCard && mediaPath && mediaPath != "" && (
        <IonImg
          src={mediaPath || DefaultHomeCardImg}
          className="notification-img"
        />
      )}
      {!storyCard && !notificationCard && (
        <IonImg src={TitlePolygonIcon} className="notification-polygon" />
      )}
    </div>
  );
};
export default HomeStoryCard;
