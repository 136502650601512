import { Media, MediaObject } from "@ionic-native/media";
import { File } from "@ionic-native/file";
import { IonButton, IonImg } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import React from "react";
import { getAudioProgress, getFileDirectory } from "../../utils/common.helper";
import AudioPlayer from "../audioplayer";
import ButtonComponent from "../common/button-component";
import TitlePolygonIcon from "../../assets/images/icons/icon-polygon-audio-record.svg";
import PlayAudio from "../../assets/images/icons/icon-polygon-orange.svg";
import MicIconOrange from "../../assets/images/icons/mic-icon-orange.svg";
import StopRecorder from "../../assets/images/icons/stop-recorder.svg";
import LanguageAppText from "../languageapptext";

interface Props {
  onBack?: () => void;
  onPost?: (file: any) => void;
}
interface State {
  recordDuration: string;
  showPlayer: boolean;
  startRecord: boolean;
}

export default class AudioRecorder extends React.Component<Props, State> {
  audio: any;
  recordInterval: any;
  filePath: string = "";
  MAX_LENGTH = 180; //seconds
  recordSeconds = 0;
  constructor(props: Props) {
    super(props);
    this.state = {
      recordDuration: "00:00",
      showPlayer: false,
      startRecord: false,
    };
  }

  startRecord = async () => {
    this.setState({ startRecord: true });
    let fileName = `record_${new Date().getDate()}${new Date().getTime()}${
      Capacitor.getPlatform() === "android" ? ".mp3" : ".m4a"
    }`;
    // "record_" + new Date().getDate() + new Date().getTime() + ".mp3";
    this.filePath = getFileDirectory().replace(/file:\/\//g, "") + fileName;
    try {
      await File.createFile(getFileDirectory(), fileName, true);
      this.audio = Media.create(this.filePath);
      // for ios use Files.documentsDirectory
      this.audio.startRecord();
      this.recordInterval = setInterval(() => {
        this.recordSeconds = this.recordSeconds + 1;
        const duration = getAudioProgress(this.recordSeconds);
        this.setState({
          recordDuration: duration,
        });
        if (this.recordSeconds === this.MAX_LENGTH) {
          this.stopRecord();
        }
      }, 1000);
    } catch (e) {
      console.log("error::", e);
    }
  };

  stopRecord = () => {
    try {
      this.audio.stopRecord();
      clearInterval(this.recordInterval);
      this.recordInterval = null;
      let data = { filename: this.filePath };
      console.log("audio", data);
      this.recordSeconds = 0;
      this.setState({
        showPlayer: true,
        startRecord: false,
      });
    } catch (error) {
      console.log("error::", error);
    }
  };

  backFromRecord = () => {
    if (this.state.startRecord) {
      this.stopRecord();
    }
    if (this.state.showPlayer) {
      this.setState({ showPlayer: false });
    }
    this.filePath = "";
    if (this.props.onBack) {
      this.props.onBack();
    }
  };

  onRedo = () => {
    this.setState({ showPlayer: false, recordDuration: "00:00" });
    this.filePath = "";
  };

  onPost = () => {
    if (this.props.onPost && this.filePath != "") {
      this.props.onPost(this.filePath);
    }
  };
  render() {
    const { recordDuration, showPlayer, startRecord } = this.state;
    return (
      <div className="audio-recorder__container mt-5">
        <div
          className="recorder-instruction__container mb-15"
          onClick={() => {
            this.backFromRecord();
          }}
        >
          <IonImg
            src={TitlePolygonIcon}
            className="audio-recorder__polygon mr-10"
          />
          <LanguageAppText
            tag={"div"}
            className="recorder-instruction text-blue font-medium"
            value={"Record an audio comment"}
            textKey={"record_audio_comment"}
          />
        </div>
        {showPlayer ? (
          <div className="recorder-post-container mb-20">
            <AudioPlayer filePath={Capacitor.convertFileSrc(this.filePath)} />
            <div className="post-btn-container">
              <ButtonComponent
                name="Redo"
                customClass="redo-btn mr-10 font-medium"
                outline
                onClick={this.onRedo}
                textKey={"redo"}
              ></ButtonComponent>
              <ButtonComponent
                name="Post"
                customClass="post-btn font-medium"
                onClick={this.onPost}
                textKey={"post"}
              ></ButtonComponent>
            </div>
          </div>
        ) : (
          <div
            onTouchStart={() => {
              this.startRecord();
            }}
            onTouchEnd={() => {
              this.stopRecord();
            }}
          >
            {!startRecord ? (
              <div
                className="record-btn__container mb-20"
                // onClick={this.startRecord}
              >
                <IonImg src={MicIconOrange} className="mic-orange" />
                <LanguageAppText
                  tag={"div"}
                  className="record-btn-text text-black ml-10"
                  value={"Press and hold to record"}
                  textKey={"press_n_hold"}
                />
              </div>
            ) : (
              <div className="recorder-container">
                <div className="recorder-btn__container mb-5">
                  <IonImg src={StopRecorder} className="recorder-stop-btn" />
                  <div className="record-btn-text text-black ml-10">
                    {recordDuration}
                  </div>
                </div>
                <LanguageAppText
                  tag={"div"}
                  className="stop-recording__text text-black"
                  value={"Release to stop recording"}
                  textKey={"release_stop_recording"}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
