import { Redirect, Route } from "react-router-dom";
import { IonApp, IonContent, IonImg, IonPage, useIonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/styles/main.scss";

import { AppContext } from "./context/app.context";
import DiagnosticQuiz from "./pages/diagnostic";
import Register from "./pages/register";
import ConfirmOTP from "./pages/confirmotp";
import CreateAccount from "./pages/createaccount";
import OtherNumbers from "./pages/othernumbers";
import PrefferedNumber from "./pages/prefferednumber";
import HearAboutUs from "./pages/hearaboutus";
import AccountSecurity from "./pages/accountsecurity";
import TermsAndCondition from "./pages/termsandconditions";
import TermsOfUse from "./pages/terms-of-use";
import TermsOfUseHindi from "./pages/terms-of-use-hindi";
import LoginWithPhone from "./pages/loginwithphone";
import BusinessType from "./pages/businesstype";
import ChangeNumbers from "./pages/changenumbers";
import Learnings from "./pages/learnings";
import StartQuiz from "./pages/startquiz";
import ReviewProgram from "./pages/reviewprogram";
import Topic from "./pages/topic";
import ActivityComplete from "./pages/activitycomplete";
import { OnboardingProvider } from "./context/onboarding.contex";
import Profile from "./pages/profile";
import YourBadges from "./pages/yourbadges";
import Activity from "./pages/activity";
import ActivityRewards from "./pages/activityrewards";
import TOCDetails from "./pages/tocdetails";
import ProfileSettings from "./pages/profilesettings";
import ChangePreferredNumber from "./pages/changeprefferednumber";
import Lottie from "react-lottie";
import EditProfile from "./pages/editprofile";
import MyStories from "./pages/mystories";
import HelpCenter from "./pages/helpcenter";
import MyRewards from "./pages/myrewards";
import React, { useContext, useEffect, useState } from "react";
import Tabs from "./pages/tabs";
import ProgramIntro from "./pages/programintro";
import { firebaseInit } from "./services/firebase.service";
import ToDo from "./pages/todo";
import RewardDetails from "./pages/rewarddetails";
import RedeemedRewards from "./pages/redeemedreward";
import Rewards from "./pages/tabs/rewards";
import RewardRedeemInfo from "./pages/rewardredeeminfo";
import Notifications from "./pages/notification";

import {
  isConnectedToInternet,
  networkCheckInit,
} from "./services/network.service";
import { nofiticationInit } from "./services/notification.service";
import CommunityStory from "./pages/communitystory";
import RecorderVideo from "./pages/recordedvideo";
import SubmitStory from "./pages/submitstory";
import DiagnosticQuestionary from "./pages/diagnosticquestionary";
import Experts from "./pages/tabs/experts";
import Congratulation from "./pages/congratulation";
import LeaderBoard from "./pages/leaderboard";
import Introduction from "./pages/introduction";
import Splash from "./pages/splash";
import { Plugins } from "@capacitor/core";
import InstallOvante from "./pages/installovante";
import ReviewStory from "./pages/reviewstory";
import NetworkError from "./pages/networkerror";
import ModalPopUp from "./components/common/modalpopup";
import Reward from "./components/reward";
import ObserverService from "./services/observer.service";
import Popup from "./components/common/popup";
import SyncSVG from "./assets/images/sync.svg";
import localnotificationService from "./services/localnotification.service";
import { notificationRedirect } from "./utils/common.helper";
import StateList from "./pages/statelist";
import DistrictList from "./pages/districtlist";
import LanguageAppText from "./components/languageapptext";
import DeleteAccount from "./pages/deleteaccount";

const { SplashScreen } = Plugins;
// const { App } = Plugins;
let isShowRewrd = false;
let observer: any = null;
const App: React.FC = (props: any) => {
  const [present, dismiss] = useIonToast();
  const [showReward, setShowReward] = useState(false);
  const [rewardData, setRewardData] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const { descEnFile, descFile }: any = useContext(AppContext);
  useEffect(() => {
    networkCheckInit(present, dismiss, descEnFile, descFile);
  }, [descEnFile, descFile]);
  useEffect(() => {
    SplashScreen.hide();
    //initialize firebase
    firebaseInit();

    //push notification init
    nofiticationInit();

    //initialize NetworkService
    networkCheckInit(present, dismiss, descEnFile, descFile);

    // check network on start
    isConnectedToInternet();

    localnotificationService.clickListener((notification: any) => {
      // console.log("local click", notification);
      const parsedData = notification.data;
      notificationRedirect(parsedData);
    });

    observer = ObserverService.observable.subscribe((value: any) => {
      if (value && value.type == "reward") {
        if (value.data.show) {
          setRewardData(value.data.data);
          setShowReward(true);
          isShowRewrd = true;
          document.addEventListener("ionBackButton", handleBack);
        } else {
          setShowReward(false);
          setRewardData(null);
          isShowRewrd = false;
          document.removeEventListener("ionBackButton", handleBack);
        }
      } else if (value && value.type === "sync") {
        setIsSyncing(value.data.isSyncing);
        if (value.data.isSyncing) {
          document.addEventListener("ionBackButton", handleBack);
        } else {
          document.removeEventListener("ionBackButton", handleBack);
        }
      }
    });
    return () => {
      if (observer) {
        observer.unsubscribe();
        observer = null;
      }
      document.removeEventListener("ionBackButton", handleBack);
      isShowRewrd = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(102, () => {
      if (isShowRewrd) {
        setShowReward(false);
        setRewardData(null);
        isShowRewrd = false;
        document.removeEventListener("ionBackButton", handleBack);
      }
    });
  };

  return (
    <AppContext.Consumer>
      {(consumer: any) => {
        return (
          <>
            <IonApp>
              {process.env.REACT_APP_WEB == "true" ? (
                <IonReactRouter>
                  <Route path="/register">
                    <Register />
                  </Route>
                  <Route path="/createaccount">
                    <CreateAccount />
                  </Route>
                  <Route path="/othernumbers">
                    <OtherNumbers />
                  </Route>
                  <Route path="/loginwithphone">
                    <LoginWithPhone />
                  </Route>
                  <Route path="/confirmotp">
                    <ConfirmOTP />
                  </Route>
                  <Route path="/changenumbers">
                    <ChangeNumbers />
                  </Route>
                  <Route path="/prefferednumber">
                    <PrefferedNumber />
                  </Route>
                  <Route path="/hearaboutus">
                    <HearAboutUs />
                  </Route>
                  <Route path="/accountsecurity">
                    <AccountSecurity />
                  </Route>
                  <Route exact path="/termsandconditions">
                    <TermsAndCondition />
                  </Route>
                  <Route exact path="/terms-of-use">
                    <TermsOfUse />
                  </Route>
                  <Route exact path="/terms-of-use-hindi">
                    <TermsOfUseHindi />
                  </Route>
                  <OnboardingProvider>
                    <Route exact path="/onboarding/businesstype">
                      <BusinessType />
                    </Route>
                    <Route exact path="/onboarding/state">
                      <StateList />
                    </Route>
                    <Route exact path="/onboarding/district">
                      <DistrictList />
                    </Route>
                    <Route exact path="/onboarding/diagnostic">
                      <DiagnosticQuiz />
                    </Route>
                    <Route exact path="/onboarding">
                      <StartQuiz />
                    </Route>
                    <Route exact path="/onboarding/diagnosticquestionary">
                      <DiagnosticQuestionary />
                    </Route>
                    <Route exact path="/editprofile">
                      <EditProfile />
                    </Route>
                    <Route exact path="/deleteaccount">
                      <DeleteAccount />
                    </Route>
                  </OnboardingProvider>
                  <Route exact path="/experts">
                    <Experts />
                  </Route>
                  <Route exact path="/congratulation">
                    <Congratulation />
                  </Route>
                  <Route exact path="/programintro">
                    <ProgramIntro />
                  </Route>
                  <Route path="/installovante">
                    <InstallOvante />
                  </Route>
                  <Route exact path="/">
                    <Redirect to={"/register"} />
                  </Route>
                </IonReactRouter>
              ) : (
                <IonReactRouter>
                  <Route path="/networkerror">
                    <NetworkError />
                  </Route>
                  <Route path="/introduction">
                    <Introduction />
                  </Route>
                  <Route path="/register">
                    <Register />
                  </Route>
                  <Route path="/loginwithphone">
                    <LoginWithPhone />
                  </Route>
                  <Route path="/confirmotp">
                    <ConfirmOTP />
                  </Route>
                  <Route path="/createaccount">
                    <CreateAccount />
                  </Route>
                  <Route path="/othernumbers">
                    <OtherNumbers />
                  </Route>
                  <Route path="/prefferednumber">
                    <PrefferedNumber />
                  </Route>
                  <Route path="/accountsecurity">
                    <AccountSecurity />
                  </Route>
                  <Route path="/hearaboutus">
                    <HearAboutUs />
                  </Route>
                  <Route path="/learnings/:id">
                    <Learnings />
                  </Route>
                  <Route path="/changenumbers">
                    <ChangeNumbers />
                  </Route>
                  <Route path="/userprofile">
                    <Profile />
                  </Route>
                  <Route path="/leaderboard">
                    <LeaderBoard />
                  </Route>
                  <Route exact path="/topic/:id">
                    <Topic />
                  </Route>
                  <Route exact path="/yourbadges">
                    <YourBadges />
                  </Route>
                  <Route exact path="/review/:type/:id">
                    <ReviewProgram />
                  </Route>
                  <Route exact path="/activityrewards">
                    <ActivityRewards />
                  </Route>
                  <Route exact path="/tocdetails">
                    <TOCDetails />
                  </Route>
                  <Route exact path="/mystories">
                    <MyStories />
                  </Route>
                  <Route exact path="/helpcenter">
                    <HelpCenter />
                  </Route>
                  <Route exact path="/myrewards">
                    <MyRewards />
                  </Route>
                  <Route exact path="/activitycomplete">
                    <ActivityComplete />
                  </Route>
                  <Route exact path="/termsandconditions">
                    <TermsAndCondition />
                  </Route>
                  <Route exact path="/terms-of-use">
                    <TermsOfUse />
                  </Route>
                  <Route exact path="/terms-of-use-hindi">
                    <TermsOfUseHindi />
                  </Route>
                  <Route exact path="/activity/:id">
                    <Activity />
                  </Route>
                  <Route exact path="/settings">
                    <ProfileSettings />
                  </Route>
                  <Route exact path="/changeprefferednumber">
                    <ChangePreferredNumber />
                  </Route>
                  <Route exact path="/programintro">
                    <ProgramIntro />
                  </Route>
                  <Route exact path="/notification">
                    <Notifications />
                  </Route>
                  <Route path="/tabs">
                    <Tabs />
                  </Route>
                  <Route exact path="/todo">
                    <ToDo />
                  </Route>
                  <Route exact path="/communitystory/:id">
                    <CommunityStory />
                  </Route>
                  <Route exact path="/recordedvideo">
                    <RecorderVideo />
                  </Route>
                  <Route exact path="/submitstory">
                    <SubmitStory />
                  </Route>
                  <OnboardingProvider>
                    <Route exact path="/onboarding/businesstype">
                      <BusinessType />
                    </Route>
                    <Route exact path="/onboarding/state">
                      <StateList />
                    </Route>
                    <Route exact path="/onboarding/district">
                      <DistrictList />
                    </Route>
                    <Route exact path="/onboarding/diagnostic">
                      <DiagnosticQuiz />
                    </Route>
                    <Route exact path="/onboarding">
                      <StartQuiz />
                    </Route>
                    <Route exact path="/onboarding/diagnosticquestionary">
                      <DiagnosticQuestionary />
                    </Route>
                    <Route exact path="/editprofile">
                      <EditProfile />
                    </Route>
                  </OnboardingProvider>
                  <Route exact path="/todo">
                    <ToDo />
                  </Route>
                  <Route exact path="/rewards">
                    <Rewards />
                  </Route>
                  <Route exact path="/experts">
                    <Experts />
                  </Route>
                  <Route exact path="/congratulation">
                    <Congratulation />
                  </Route>
                  <Route exact path="/rewarddetails/:type/:id">
                    <RewardDetails />
                  </Route>
                  <Route exact path="/redeemedreward">
                    <RedeemedRewards />
                  </Route>
                  <Route exact path="/rewardinfo/:type/:id">
                    <RewardRedeemInfo />
                  </Route>
                  <Route exact path="/reviewStory">
                    <ReviewStory />
                  </Route>
                  <Route exact path="/splash">
                    <Splash />
                  </Route>
                  <Route exact path="/deleteaccount">
                    <DeleteAccount />
                  </Route>
                  <Route exact path="/">
                    <Redirect to={"/splash"} />
                  </Route>
                  {showReward && (
                    <Popup
                      customClass="tools-popup"
                      onClose={() => {
                        ObserverService.handleEvents("reward", { show: false });
                      }}
                    >
                      <Reward data={rewardData} />
                    </Popup>
                  )}
                </IonReactRouter>
              )}

              {consumer.lottieOption && (
                <Lottie
                  options={consumer.lottieOption}
                  isStopped={!consumer.isLottiePlay}
                  height={400}
                  width={400}
                />
              )}
            </IonApp>
            {isSyncing && (
              <ModalPopUp
                showModal={isSyncing}
                onCloseModal={() => {
                  setIsSyncing(false);
                }}
              >
                <div
                  style={{
                    margin: "auto",
                    width: "100%",
                    padding: 10,
                  }}
                >
                  <IonImg src={SyncSVG} style={{ height: 50 }} />
                  <div style={{ textAlign: "center", marginTop: 10 }}>
                    {/* <span>Syncing your data, please wait...</span> */}
                    <LanguageAppText
                      tag={"span"}
                      className="black_text"
                      value={"Syncing your data, please wait..."}
                      textKey={"sync_data"}
                    />
                  </div>
                </div>
              </ModalPopUp>
            )}
          </>
        );
      }}
    </AppContext.Consumer>
  );
};
export default App;
