import SQLiteService from "../sqlite.service";

const TABLE_NAME = "coins";
const COLUMN_ID = "id";
const COLUMN_COINS = "coins";
const COLUMN_TYPE = "type";
const COLUMN_CREATED_AT = "createdAt";
const COLUMN_UPDATED_AT = "updatedAt";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_COINS +
  " int, " +
  COLUMN_TYPE +
  " TEXT," +
  COLUMN_CREATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')) ," +
  COLUMN_UPDATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')))";

let _singleton = true;
let _instance: OfflineCoinsService;
let sqliteService: SQLiteService = SQLiteService.instance;
class OfflineCoinsService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use OfflineCoinsService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new OfflineCoinsService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insert(value: any) {
    let values = "";
    values += "" + value.coins;
    values += ",'" + value.type + "'";

    let col = COLUMN_COINS + ", " + COLUMN_TYPE;
    let result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
    return result;
  }
}

export default OfflineCoinsService;
