import { IonButton, IonImg, IonItemDivider, IonThumbnail } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./style.scss";
import LogoOvante from "../../assets/images/logo-ovante-small.svg";
import DefaultProfileImg from "../../assets/images/global-default_new.jpeg";
import LanguageText from "../languagetext";

interface Props {
  title?: string;
  name?: string;
  bgColor?: string;
  customClass?: string;
  imgPath?: any;
}

const HomeProfileHeader: React.FC<Props> = ({
  title,
  name,
  customClass,
  imgPath,
  bgColor,
}) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  return (
    <div
      className={`home-header__container  ${customClass || ""}`}
      style={{
        background: `${bgColor ? bgColor : "#45b7d9"}`,
      }}
    >
      <div className="home-header__details text-white">
        <div className="logo mb-15">
          <IonImg src={LogoOvante} className="header-details-logo" />
        </div>
        {title && title != "" && (
          <LanguageText
            tag={"div"}
            className="home-header__title font-bold mb-5"
            value={title}
          ></LanguageText>
        )}
        {/* <div className="home-header__title font-bold mb-5">Make more money</div> */}
        <div className="profile-name">{name}</div>
      </div>
      <div className="profile-img">
        {mediaPath && (
          <IonThumbnail className="home-profile__img">
            <img src={mediaPath || DefaultProfileImg} />
          </IonThumbnail>
        )}
      </div>
    </div>
  );
};
export default HomeProfileHeader;
