import SQLiteService from "../sqlite.service";

const TABLE_NAME = "todo";
const COLUMN_ID = "id";
const COLUMN_TITLE = "title";
const COLUMN_DUE_DATE = "dueDate";
const COLUMN_TYPE = "type";
const COLUMN_IS_COMPLETED = "isCompleted";
const COLUMN_MACHINE_NAME = "machineName";
const COLUMN_ACTIVITY_ID = "activityId";
const COLUMN_IS_REMINDER = "isReminder";
const COLUMN_CREATED_AT = "createdAt";
const COLUMN_UPDATED_AT = "updatedAt";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_TITLE +
  " TEXT, " +
  COLUMN_DUE_DATE +
  " TEXT," +
  COLUMN_TYPE +
  " TEXT," +
  COLUMN_IS_COMPLETED +
  " boolean," +
  COLUMN_MACHINE_NAME +
  " TEXT," +
  COLUMN_ACTIVITY_ID +
  " int," +
  COLUMN_IS_REMINDER +
  " boolean," +
  COLUMN_CREATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')) ," +
  COLUMN_UPDATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')))";

let _singleton = true;
let _instance: OfflineTodoService;
let sqliteService: SQLiteService = SQLiteService.instance;
class OfflineTodoService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use OfflineTodoService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new OfflineTodoService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insert(value: any) {
    const resp = await this.getData(value.machineName);
    let result;
    if (!resp.error) {
      if (resp.length > 0) {
      } else {
        let isCompleted = 0;
        if (value.isCompleted) {
          isCompleted = value.isCompleted;
        }

        let isReminder = 0;
        if (value.isReminder) {
          isReminder = value.isReminder;
        }
        let values = "";
        values += "'" + value.title;
        values += "','" + value.dueDate;
        values += "','" + value.type;
        values += "'," + isCompleted;
        values += ",'" + value.machineName;
        values += "'," + value.activityId;
        values += "," + isReminder + "";

        let col =
          COLUMN_TITLE +
          ", " +
          COLUMN_DUE_DATE +
          ", " +
          COLUMN_TYPE +
          ", " +
          COLUMN_IS_COMPLETED +
          ", " +
          COLUMN_MACHINE_NAME +
          ", " +
          COLUMN_ACTIVITY_ID +
          ", " +
          COLUMN_IS_REMINDER;
        result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
        console.log("res", values, col, result);
      }
    }
    return result;
  }

  public async getData(machineName: any) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_MACHINE_NAME} = '${machineName}' and ${COLUMN_IS_COMPLETED} = 0`;
    let result: any = await sqliteService.getData(query);
    return result;
  }

  public async changeStatus(machineName: any) {
    let result: any = await sqliteService.update(
      `update ${TABLE_NAME} set ${COLUMN_IS_COMPLETED} = 1 where ${COLUMN_MACHINE_NAME} = '${machineName}'`
    );
    return result;
  }
}

export default OfflineTodoService;
