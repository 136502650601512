import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import {
  IonButton,
  IonCard,
  IonContent,
  IonImg,
  IonItem,
  IonList,
  IonPage,
  IonSpinner,
  IonThumbnail,
} from "@ionic/react";
import { getOutcomes, getTopicById } from "../../services/program.service";
import RegisterHeader from "../../components/common/register-header";
import ExtraActivityCard from "../../components/extraactivitycard";
import Coins from "../../assets/images/icons/coins.svg";
import PlayOrange from "../../assets/images/icons/play-orange.svg";
import Loader from "../../components/common/loader";
import { File } from "@ionic-native/file";
import {
  getFileDirectory,
  unZipDownloadedFile,
} from "../../utils/common.helper";
import { downloadFile } from "../../services/filetransfer.service";
import { Capacitor } from "@capacitor/core";
import VideoPlayer from "../../components/videoplayer";
import { AppContext } from "../../context/app.context";
import Back from "../../assets/images/icons/back.svg";
import LanguageImage from "../../components/languageimage";
import LanguageText from "../../components/languagetext";
import LanguageAppText from "../../components/languageapptext";
import { isConnectedToInternet } from "../../services/network.service";
import OfflineTopicService from "../../services/sqlite/offlinetopic.service";
import OfflineActivityService from "../../services/sqlite/offlineactivity.service";
import OfflineTodoService from "../../services/sqlite/offlinetodo.service";
import OfflineContentService from "../../services/sqlite/offlinecontent.service";

import TopicService from "../../services/topic.service";
import Messages from "../../config/messages";
let isFullVideo = false;
const Topic: React.FC = () => {
  const { language, toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  let { id }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [activities, setActivities]: any = useState([]);
  const [completedActivities, setCompletedActivities]: any = useState([]);
  const [introVideo, setIntroVideo]: any = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    getDetails();

    // document.addEventListener("ionBackButton", handleBack);

    // return () => {
    //   document.removeEventListener("ionBackButton", handleBack);
    //   isFullVideo = false;
    // };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isFullVideo) {
        isFullVideo = false;
        setIsFullScreen(false);
      } else {
        history.goBack();
      }
    });
  };

  const getDetails = async () => {
    let resp: any = null;
    if (isConnectedToInternet()) {
      resp = await getTopicById(id);
      const outComesResp: any = await getOutcomes(id);
      if (resp && !resp.error) {
        let localActivities: any = [];
        const activities = resp.activities;
        if (activities && activities.length > 0 && resp.topic) {
          for (let activity in activities) {
            let item: any = activities[activity];
            item["topicPackage"] = resp.topic.nodeFieldData.packageFile;
            let thumbFileData: any = null;
            try {
              let parsedData = JSON.parse(item.nodeFieldData.teaserImage);
              if (parsedData) {
                thumbFileData = parsedData[language] || parsedData["en"];
                let file = thumbFileData.fileName.split(".")[0];
                let url = `/file/download/${thumbFileData.uri}`;

                await File.checkFile(getFileDirectory(), thumbFileData.fileName)
                  .then(() => {
                    item["localFile"] = `${getFileDirectory()}${
                      thumbFileData.fileName
                    }`;
                  })
                  .catch(async () => {
                    const downRes: any = await downloadFile(
                      url,
                      file,
                      thumbFileData.fileName.split(".")[1]
                    );

                    if (!downRes.error) {
                      item["localFile"] = `${getFileDirectory()}${
                        thumbFileData.fileName
                      }`;
                    }
                  });
              }
            } catch (error) {}
            let outComesItem: any = null;
            if (outComesResp && !outComesResp.error && outComesResp.outcome) {
              outComesItem = outComesResp.outcome.filter((outComeItem: any) => {
                return outComeItem.activityId === item.id;
              });
            }
            let outComeMetaData = outComesItem[0].data;
            let localOutComeMetaData = [];
            if (outComeMetaData && outComeMetaData.length > 0) {
              for (let item in outComeMetaData) {
                let outComeItem = outComeMetaData[item];
                if (outComeItem.type === "badge") {
                  let file = outComeItem.metadata.image?.fileName.split(".")[0];
                  let url = `/file/download/${outComeItem.metadata.image?.uri}`;

                  await File.checkFile(
                    getFileDirectory(),
                    outComeItem.metadata.image?.fileName
                  )
                    .then(() => {
                      outComeItem.metadata[
                        "localFile"
                      ] = `${getFileDirectory()}${
                        outComeItem.metadata.image?.fileName
                      }`;
                    })
                    .catch(async () => {
                      const downRes: any = await downloadFile(
                        url,
                        file,
                        outComeItem.metadata.image?.fileName.split(".")[1]
                      );

                      if (!downRes.error) {
                        outComeItem.metadata[
                          "localFile"
                        ] = `${getFileDirectory()}${
                          outComeItem.metadata.image?.fileName
                        }`;
                      }
                    });
                } else if (outComeItem.type === "tools") {
                  let iconImage = outComeItem.metadata.iconImage
                    ? JSON.parse(outComeItem.metadata.iconImage)
                    : null;
                  if (iconImage) {
                    let tempFile = iconImage.uri.split(".")[0];
                    let ext = iconImage.uri.split(".")[1];
                    let file = tempFile.split("/")[1];
                    let url = `/file/download/${iconImage.uri}`;

                    await File.checkFile(getFileDirectory(), `${file}.${ext}`)
                      .then(() => {
                        outComeItem.metadata[
                          "localFile"
                        ] = `${getFileDirectory()}${`${file}.${ext}`}`;
                      })
                      .catch(async () => {
                        const downRes: any = await downloadFile(url, file, ext);

                        if (!downRes.error) {
                          outComeItem.metadata[
                            "localFile"
                          ] = `${getFileDirectory()}${`${file}.${ext}`}`;
                        }
                      });
                  }
                }
                localOutComeMetaData.push(outComeItem);
              }
            }
            outComesItem[0].data = localOutComeMetaData;
            const activitiesCompleted: any = resp.progress.activitiesCompleted;
            let activitiesCompletedArray: any[] = [];
            if (activitiesCompleted && activitiesCompleted != "") {
              activitiesCompletedArray = activitiesCompleted.split(",");
            }
            let isCompleted = false;
            if (activitiesCompletedArray.includes(item.id.toString())) {
              isCompleted = true;
            }

            localActivities.push(item);
            await OfflineActivityService.instance.insertorupdate({
              activityId: item.id,
              topicId: id,
              data: JSON.stringify(item).replace(/\'/g, "''"),
              outcomes:
                outComesItem && outComesItem[0].data
                  ? JSON.stringify(outComesItem[0].data).replace(/\'/g, "''")
                  : JSON.stringify([]),
              isCompleted,
            });
          }
        }
        if (localActivities.length > 0) {
          resp.activities = localActivities;
        }
        OfflineTopicService.instance.insertorupdate({
          topicId: id,
          data: JSON.stringify(resp).replace(/\'/g, "''"),
        });

        if (outComesResp && !outComesResp.error) {
          //todo insert
          if (outComesResp.todos && outComesResp.todos.length > 0) {
            for (let item in outComesResp.todos) {
              const data = outComesResp.todos[item];
              let reqObj = {
                title: data.title || "",
                dueDate: data.dueDate ? data.dueDate : null,
                machineName: data.machineName,
                activityId: data.activityId,
                type: "task",
                isReminder: data.isReminder || false,
              };
              await OfflineTodoService.instance.insert(reqObj);
            }
          }

          // session/bookmark data insert
          if (
            outComesResp.session_data &&
            outComesResp.session_data.length > 0
          ) {
            for (let item in outComesResp.session_data) {
              const reqObj = outComesResp.session_data[item];
              await OfflineContentService.instance.insertorupdate({
                activityId: reqObj.node.id,
                bookmarkData:
                  typeof reqObj.currentSession.bookmarkData === "string"
                    ? reqObj.currentSession.bookmarkData
                    : JSON.stringify(reqObj.currentSession.bookmarkData),
                sessionStore:
                  typeof reqObj.currentSession.sessionStore === "string"
                    ? reqObj.currentSession.sessionStore
                    : JSON.stringify(reqObj.currentSession.sessionStore),
                isCompleted: reqObj.isCompleted,
              });
            }
          }
        }
      }
    } else {
      const result: any = await OfflineTopicService.instance.getData(id);
      if (result && !result.error && result.length > 0) {
        resp = JSON.parse(result.item(0).topicData);
        const activities = resp.activities;
        const pendingActivities = [];
        if (activities) {
          for (let activity in activities) {
            let item: any = activities[activity];
            const activityData: any =
              await OfflineActivityService.instance.getData(item.id);
            if (
              activityData &&
              !activityData.error &&
              activityData.length > 0
            ) {
              if (!activityData.item(0).isCompleted) {
                pendingActivities.push(item);
              }
            }
          }
          resp.activities = pendingActivities;
        }
      }
    }

    if (resp && !resp.error) {
      const activities = resp.activities;
      const activitiesCompleted: any = resp.progress.activitiesCompleted;
      let activitiesCompletedArray: any[] = [];
      if (activitiesCompleted && activitiesCompleted != "") {
        activitiesCompletedArray = activitiesCompleted.split(",");
      }
      let otherActivities: any = [];
      let completedActivities: any = [];
      if (activities) {
        activities.map((activity: any) => {
          if (!activitiesCompletedArray.includes(activity.id.toString())) {
            otherActivities.push(activity);
          } else {
            completedActivities.push(activity);
          }
        });
      }
      let title = "",
        introVideo = "";
      let packageFile = null;
      if (resp.topic) {
        try {
          let parsedData = JSON.parse(resp.topic.nodeFieldData.title);
          title = parsedData[language] || parsedData["en"];

          let packageFileData = JSON.parse(
            resp.topic.nodeFieldData.packageFile
          );
          packageFile = packageFileData[language] || packageFileData["en"];

          let parsedIntroVideo = JSON.parse(resp.topic.nodeFieldData.video);
          introVideo = parsedIntroVideo[language] || parsedIntroVideo["en"];
        } catch (error) {}
      }
      setHeaderTitle(title);
      setActivities(otherActivities);
      setCompletedActivities(completedActivities);
      setIntroVideo(introVideo);

      await onLoadTopic(packageFile);
    }
    // setLoading(false);
  };

  const onLoadTopic = async (packageFile: any) => {
    console.log("onLoad", packageFile);

    // let topicFolder = `program1_topic1`;
    // let url = `/file/download/zip/program1_topic1.zip`;

    let topicFolder = packageFile.fileName.split(".")[0];

    let url = `/file/download/${packageFile.uri}`;
    // console.log("url", topicFolder, url);
    await File.checkDir(getFileDirectory(), "js")
      .then(async (result) => {
        await File.removeDir(getFileDirectory(), "js").then(async () => {
          await File.createDir(getFileDirectory(), "js", true).then(
            async () => {
              await File.listDir(
                `${File.applicationDirectory}public/assets`,
                "js"
              )
                .then((resp) => {
                  resp.map(async (item: any) => {
                    await File.copyFile(
                      `${File.applicationDirectory}public/assets/js`,
                      item.name,
                      `${getFileDirectory()}js`,
                      item.name
                    )
                      .then((result) => {
                        console.log("copyfile", result);
                      })
                      .catch(async (e) => {
                        console.log("copyfile error", e);
                      });
                  });
                })
                .catch(async (e) => {
                  console.log(e);
                });
            }
          );
        });
      })
      .catch(async () => {
        await File.createDir(getFileDirectory(), "js", true).then(async () => {
          await File.listDir(`${File.applicationDirectory}public/assets`, "js")
            .then((resp) => {
              resp.map(async (item: any) => {
                await File.copyFile(
                  `${File.applicationDirectory}public/assets/js`,
                  item.name,
                  `${getFileDirectory()}js`,
                  item.name
                )
                  .then((result) => {
                    console.log("copyfile", result);
                  })
                  .catch(async (e) => {
                    console.log("copyfile error", e);
                  });
              });
            })
            .catch(async (e) => {
              console.log(e);
            });
        });
      });

    let isExist = await TopicService.instance.checkExist(id);
    try {
      const dirExist = await File.checkDir(getFileDirectory(), topicFolder);
      if (dirExist) {
        setShowVideo(isConnectedToInternet() && !isExist);
        try {
          const videoExist = await File.checkFile(
            `${getFileDirectory()}${topicFolder}/intro/`,
            `intro_video.mp4`
          );

          if (videoExist) {
            setIntroVideo(
              Capacitor.convertFileSrc(
                `${getFileDirectory()}${topicFolder}/intro/intro_video.mp4`
              )
            );
            setShowVideo(!isExist);
          }
        } catch (error) {}
        setLoading(false);
      } else {
        const resDownload: any = await downloadFile(url, topicFolder);
        if (!resDownload.error) {
          try {
            const res: number = await unZipDownloadedFile(
              resDownload.data,
              getFileDirectory(),
              topicFolder
            );
            console.log(res);
            if (res === 0) {
              setShowVideo(isConnectedToInternet() && !isExist);

              try {
                const videoExist = await File.checkFile(
                  `${getFileDirectory()}${topicFolder}/intro/`,
                  `intro_video.mp4`
                );
                File.removeFile(getFileDirectory(), `${topicFolder}.zip`);
                if (videoExist) {
                  setIntroVideo(
                    Capacitor.convertFileSrc(
                      `${getFileDirectory()}${topicFolder}/intro/intro_video.mp4`
                    )
                  );
                  setShowVideo(!isExist);
                }
              } catch (error) {}
            }
          } catch (error) {
            console.log(error);
          }
          setLoading(false);
        } else {
          setLoading(false);
          toastMessage(Messages.DOWNLOAD_ERROR, "danger");
          history.goBack();
        }
      }
    } catch (error) {
      const resDownload: any = await downloadFile(url, topicFolder);
      if (!resDownload.error) {
        try {
          const res: number = await unZipDownloadedFile(
            resDownload.data,
            getFileDirectory(),
            topicFolder
          );
          console.log(res);
          if (res === 0) {
            setShowVideo(isConnectedToInternet() && !isExist);

            try {
              const videoExist = await File.checkFile(
                `${getFileDirectory()}${topicFolder}/intro/`,
                `intro_video.mp4`
              );
              File.removeFile(getFileDirectory(), `${topicFolder}.zip`);
              if (videoExist) {
                setIntroVideo(
                  Capacitor.convertFileSrc(
                    `${getFileDirectory()}${topicFolder}/intro/intro_video.mp4`
                  )
                );
                setShowVideo(!isExist);
              }
            } catch (error) {}
          }
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toastMessage(Messages.DOWNLOAD_ERROR, "danger");
        history.goBack();
      }
    }
    // await File.checkDir(getFileDirectory(), topicFolder)
    //   .then(() => {
    //     setIntroVideo(
    //       Capacitor.convertFileSrc(
    //         `${getFileDirectory()}${topicFolder}/intro/intro_video.mp4`
    //       )
    //     );
    //     setLoading(false);
    //     setShowVideo(!isExist);
    //   })
    //   .catch(async () => {
    //     const resDownload: any = await downloadFile(url, topicFolder);
    //     if (!resDownload.error) {
    //       try {
    //         const res: number = await unZipDownloadedFile(
    //           resDownload.data,
    //           getFileDirectory(),
    //           topicFolder
    //         );
    //         console.log(res);
    //         if (res === 0) {
    //           setIntroVideo(
    //             Capacitor.convertFileSrc(
    //               `${getFileDirectory()}${topicFolder}/intro/intro_video.mp4`
    //             )
    //           );
    //         }
    //       } catch (error) {
    //         console.log(error);
    //       }
    //       setLoading(false);
    //       setShowVideo(!isExist);
    //     }
    //   });
  };

  const onActivityClick = (activity: any) => {
    history.push({
      pathname: `/activity/${activity.id}`,
    });
  };
  return (
    <IonPage
      className="page-topic "
      style={showVideo ? { backgroundColor: "black" } : {}}
    >
      {!showVideo && (
        <RegisterHeader
          pageName={headerTitle}
          backBtn={true}
          customClass="header-green"
        ></RegisterHeader>
      )}
      <IonContent
        className="page-content pb-0 bg-light-orange"
        style={showVideo ? { maxWidth: "none" } : {}}
      >
        {loading ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Loader></Loader>
            <br />
            <LanguageAppText
              tag={"span"}
              value={"Loading, Please Wait..."}
              textKey={"loading_please_wait"}
            />
          </div>
        ) : (
          <div className="bg-light-orange">
            {showVideo && (
              <div className="page-reward-video stretch-container">
                <VideoPlayer
                  url={introVideo}
                  hideLike={true}
                  isFullScreen={true}
                  hideFullScreen
                  onVideoEnd={async () => {
                    await TopicService.instance.insert({
                      topicId: id,
                    });
                    setShowVideo(false);
                  }}
                />
              </div>
            )}
            {!showVideo && (
              <>
                {activities && activities.length > 0 && (
                  <div className="bg-light-orange stretch-container">
                    <LanguageAppText
                      tag={"div"}
                      className="page__section-title mb-10"
                      value={"Choose an activity"}
                      textKey={"choose_activity"}
                    />
                    {activities.map((activity: any, index: number) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            onActivityClick(activity);
                          }}
                        >
                          <ExtraActivityCard
                            label={activity.nodeFieldData.title}
                            midContent={
                              activity.earnCoins
                                ? activity.earnCoins
                                : undefined
                            }
                            thumbnail={activity.nodeFieldData.teaserImage}
                            localThumb={activity.localFile}
                            //   midIcon={Coins}
                            //   rightIcon={PlayOrange}
                          ></ExtraActivityCard>
                        </div>
                      );
                    })}
                  </div>
                )}
                {completedActivities && completedActivities.length > 0 && (
                  <div className="mt-30">
                    <LanguageAppText
                      tag={"div"}
                      className="page__section-title"
                      value={"Completed activities"}
                      textKey={"completed_activities"}
                    />
                    <LanguageAppText
                      tag={"p"}
                      className="page-content__infotext"
                      value={"Go back to activities you already completed"}
                      textKey={"back_to_activity"}
                    />
                    {/* <div className="page__section-title">
                        Completed activities
                      </div>
                      <p className="page-content__infotext">
                        Go back to activities you already completed
                      </p> */}
                    {completedActivities.map((activity: any, index: number) => {
                      return (
                        <IonCard
                          key={index}
                          className="ion-no-margin capsul-card capsul-card__extra-activities mb-20"
                          onClick={() => {
                            onActivityClick(activity);
                          }}
                        >
                          <IonList className="ion-no-padding">
                            <IonItem>
                              <div className="capsul-card__thum-group">
                                <IonThumbnail
                                  slot="start"
                                  className="ion-no-padding ion-no-margin ion-margin-end capsul-card__thum-group_overlay relative"
                                >
                                  {isConnectedToInternet() ? (
                                    <LanguageImage
                                      value={activity.nodeFieldData.teaserImage}
                                    />
                                  ) : (
                                    <IonImg
                                      src={Capacitor.convertFileSrc(
                                        activity.localFile
                                      )}
                                    />
                                  )}
                                </IonThumbnail>
                                <div className="icon__completed"></div>
                              </div>

                              <div className="capsul-card__mid">
                                <LanguageText
                                  tag={"label"}
                                  className="capsul-card__label"
                                  value={activity.nodeFieldData.title}
                                ></LanguageText>
                              </div>
                              <div className="capsul-card__last">
                                <img src={PlayOrange} />
                              </div>
                            </IonItem>
                          </IonList>
                        </IonCard>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
export default Topic;
