import { IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import Loader from "../../components/common/loader";
import {
  APP_DEFAULT_SCREEN,
  APP_VERSION,
  FIRST_LOGIN,
  INIT_STATE_APP,
  INIT_STATE_ONBOARD,
} from "../../config/appconfig";
import { AppContext } from "../../context/app.context";
import "./style.scss";
import SplashLogo from "../../assets/images/splash-logo.svg";
import { userLoginCount, userProfile } from "../../services/user.service";
import { Capacitor } from "@capacitor/core";
import { getLangDescriptor } from "../../services/lang-descriptor.service";
import AppConfigService from "../../services/appconfig.service";
import moment from "moment";
import ProgramSessionService from "../../services/programsession.service";
import SyncService from "../../services/sqlite/sync.service";
import { isConnectedToInternet } from "../../services/network.service";

const Splash: React.FC = () => {
  const {
    hasToken,
    userDetails,
    setAppState,
    language,
    getDescriptorFile,
    setUserDetails,
    setInitAppState,
  }: any = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    if (!isConnectedToInternet()) {
      history.push({
        pathname: "/networkerror",
      });
      return;
    }
    checkLang();
    loginCount();
    if (Capacitor.getPlatform() !== "web") {
      syncData();
      SyncService.instance.syncToServer();
    }
  }, []);

  const checkLang = async () => {
    await getLangDescriptor(language);
    await getDescriptorFile(language);
    redirect();
  };

  const syncData = async () => {
    await ProgramSessionService.instance.getSyncData();
  };
  const loginCount = async () => {
    let callAPI = true;

    if (Capacitor.getPlatform() !== "web") {
      const variableRes: any = await AppConfigService.instance.getData(
        "name",
        FIRST_LOGIN
      );
      if (!variableRes.error) {
        if (variableRes.length > 0) {
          if (
            !moment("DD-MM-YYYY").isAfter(
              moment(variableRes.item(0).value, "DD-MM-YYYY")
            )
          ) {
            callAPI = false;
          }
        }
      }
    }

    if (callAPI) {
      if (hasToken === "true") {
        const resp = await userLoginCount();
        console.log("loginCount", resp);

        if (Capacitor.getPlatform() !== "web") {
          AppConfigService.instance.insertorupdate(
            FIRST_LOGIN,
            moment().format("DD-MM-YYYY")
          );
        }
      }
    }
  };

  const redirect = () => {
    setTimeout(async () => {
      if (hasToken == "false") {
        history.replace({
          pathname: `/introduction`,
        });
      } else {
        let route = "/register";
        const userDetails: any = await userProfile();
        if (!userDetails.error) {
          setUserDetails(userDetails);
          setInitAppState(
            userDetails.diagnosticQuiz === true
              ? INIT_STATE_APP
              : INIT_STATE_ONBOARD
          );
          if (!userDetails.isPartnerUser && !userDetails.howYouHearAboutUs) {
            history.replace({
              pathname: "/hearaboutus",
              state: {
                fromPage: userDetails.isPartnerUser ? "matched" : "not-matched",
                phoneNumbers: JSON.stringify({
                  phoneNumber1: userDetails.phoneNumber1,
                }),
              },
            });
            return;
          } else if (!userDetails.tcConfirm) {
            route = "/termsandconditions";
          } else if (!userDetails.isRegistered) {
            // const profiileStarted = await storageSevice.get(PROFILE_STARTED);

            // if (profiileStarted) {
            //   route = "/onboarding/diagnostic";
            // } else {
            //   route = "/register";
            // }
            route = "/onboarding/diagnostic";
          } else if (!userDetails.diagnosticQuiz) {
            route = "/onboarding/diagnosticquestionary";
          } else {
            setAppState(INIT_STATE_APP);
            route = APP_DEFAULT_SCREEN;
          }
        }
        history.replace({
          pathname: route,
        });
      }
    }, 3000);
  };

  return (
    <IonPage className="page-splash">
      <IonContent fullscreen className="page-content">
        <div className="page-splash-content">
          <IonImg src={SplashLogo} className="splash-logo mb-10" />
          {/* <div
            className="text-spider-black font-medium mb-10"
            style={{ color: "white" }}
          >
            Version {APP_VERSION}
          </div> */}
          <Loader />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Splash;
