import SQLiteService from "./sqlite.service";

const TABLE_NAME = "topic_intro";

const COLUMN_TOPIC_ID = "topicId";
const COLUMN_IS_PLAYED = "isPlayed";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_TOPIC_ID +
  " int, " +
  COLUMN_IS_PLAYED +
  " boolean default false )";

let _singleton = true;
let _instance: TopicService;
let sqliteService: SQLiteService = SQLiteService.instance;
class TopicService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use TopicService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new TopicService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insert(data: any) {
    let result;
    let values = "";
    values += "" + data.topicId;
    values += ",true";

    result = await sqliteService.insert(TABLE_NAME, values);
    return result;
  }

  public async checkExist(topicId: any) {
    let query = `select * from ${TABLE_NAME} where ${COLUMN_TOPIC_ID}=${topicId} and ${COLUMN_IS_PLAYED} = true`;

    let result: any = await sqliteService.getData(query);
    let isExist = false;
    if (result && !result.error && result.length > 0) {
      isExist = result.item(0)[COLUMN_IS_PLAYED];
    }
    return isExist;
  }
}

export default TopicService;
