import React, { useContext, useEffect, useRef, useState } from "react";
import { IonContent, IonImg, IonPage, IonSpinner } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import {
  convertLocalToUTC,
  getFileDirectory,
  getFormattedUrl,
  getQueryStrings,
  unZipDownloadedFile,
} from "../../utils/common.helper";
import { File } from "@ionic-native/file";
import WebView from "../../components/webview";
import { useParams } from "react-router-dom";
import {
  getActivityById,
  getTopicCompleted,
  markActivityComplete,
  programActivityStatus,
} from "../../services/program.service";
import { useHistory, useLocation } from "react-router";
import { downloadFile, uploadFile } from "../../services/filetransfer.service";

import ModalPopUp from "../../components/common/modalpopup";
import { receiveMessage } from "../../utils/iframe.helper";
import UploadMedia from "../uploadmedia";
import Loader from "../../components/common/loader";
import { analytics, commit, fetch } from "../../services/contentapi.service";
import Popup from "../../components/common/popup";
import ButtonComponent from "../../components/common/button-component";
import ActivityExit from "../../assets/images/icons/activity-exit.svg";
import {
  addOvanteTask,
  addTask,
  changeOvanteTaskStatus,
  changeTaskStatus,
} from "../../services/todo.service";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import moment from "moment";
import SubmitStory from "../submitstory";
import UploadVideo from "../../components/uploadVideo";
import LanguageAppText from "../../components/languageapptext";
import ProgramSessionService from "../../services/programsession.service";
import OfflineTodoService from "../../services/sqlite/offlinetodo.service";
import SyncService from "../../services/sqlite/sync.service";
import OfflineAnalyticsService from "../../services/sqlite/offlineanalytics.service";
import OfflineActivityService from "../../services/sqlite/offlineactivity.service";
import OfflineContentService from "../../services/sqlite/offlinecontent.service";
import OfflineCoinsService from "../../services/sqlite/offlinecoins.service";
import { addCoins, reduceCoins } from "../../services/user.service";
import RewardAnimationData from "../../assets/reward.json";
import localnotificationService from "../../services/localnotification.service";
import { isConnectedToInternet } from "../../services/network.service";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer";
import { Http } from "@capacitor-community/http";
import {
  createCreditProfile,
  getCreditProfile,
  updateCreditProfile,
} from "../../services/usercreditprofile.service";
let eventData: any;
let iframeRef: any;
let programId: any,
  topicId: any,
  sessionId = 0;
let isExitModal = false;
const Activity: React.FC = () => {
  let param: any = useParams();
  const { pathname }: any = useLocation();
  // let url = state.url;
  const toolName = getQueryStrings(pathname).toolName;
  let id = typeof param.id === "number" ? param.id : parseInt(param.id);
  const history = useHistory();
  const {
    toastMessage,
    language,
    playLottieAnimation,
    stopLottieAnimation,
  }: any = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [launchUrl, setLaunchUrl] = useState("");
  const [showExitModal, setShowExitModal] = useState(false);
  let totalTime: any = 0,
    visitCount = 0;

  let interval: any;
  useEffect(() => {
    onLoad();

    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
      isExitModal = false;
      iframeRef = null;
      programId = null;
      topicId = null;
      sessionId = 0;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isExitModal) {
        isExitModal = false;
        setShowExitModal(false);
      } else {
        // isExitModal = true;
        // setShowExitModal(true);
        iframeRef.contentWindow.postMessage(
          {
            type: "backclick",
          },
          "*"
        );
      }
    });
  };

  const onLoad = async () => {
    console.log("onLoad");
    let resp: any = null;
    if (isConnectedToInternet()) {
      resp = await getActivityById(id);
    } else {
      const result: any = await OfflineActivityService.instance.getData(id);
      if (result && !result.error && result.length > 0) {
        resp = JSON.parse(result.item(0).activityData);
      }
    }
    console.log("activity", resp);
    if (resp && !resp.error) {
      programId = resp.programId;
      topicId = resp.parent;
      let parsedData = JSON.parse(resp.topicPackage);
      let packageFile = parsedData[language] || parsedData["en"];
      let activityFolder = resp.nodeFieldData.activityKey;

      let topicFolder = packageFile.fileName.split(".")[0];
      let url = `/file/download/${packageFile.uri}`;

      await File.checkDir(getFileDirectory(), "js")
        .then(async () => {
          await File.removeDir(getFileDirectory(), "js").then(async () => {
            await File.createDir(getFileDirectory(), "js", true).then(
              async () => {
                await File.listDir(
                  `${File.applicationDirectory}public/assets`,
                  "js"
                )
                  .then((resp) => {
                    resp.map(async (item: any) => {
                      await File.copyFile(
                        `${File.applicationDirectory}public/assets/js`,
                        item.name,
                        `${getFileDirectory()}js`,
                        item.name
                      )
                        .then((result) => {
                          console.log("copyfile", result);
                        })
                        .catch(async (e) => {
                          console.log("copyfile error", e);
                        });
                    });
                  })
                  .catch(async (e) => {
                    console.log(e);
                  });
              }
            );
          });
        })
        .catch(async () => {
          await File.createDir(getFileDirectory(), "js", true).then(
            async () => {
              await File.listDir(
                `${File.applicationDirectory}public/assets`,
                "js"
              )
                .then((resp) => {
                  resp.map(async (item: any) => {
                    await File.copyFile(
                      `${File.applicationDirectory}public/assets/js`,
                      item.name,
                      `${getFileDirectory()}js`,
                      item.name
                    )
                      .then((result) => {
                        console.log("copyfile", result);
                      })
                      .catch(async (e) => {
                        console.log("copyfile error", e);
                      });
                  });
                })
                .catch(async (e) => {
                  console.log(e);
                });
            }
          );

          // console.log("copy", resp, `${File.applicationDirectory}www/assets/js/`);
        });

      File.checkDir(getFileDirectory(), topicFolder)
        .then(() => {
          setLaunchUrl(
            Capacitor.convertFileSrc(
              `${getFileDirectory()}${topicFolder}/${activityFolder}/index.html?random=${new Date().getTime()}`
            )
          );
          addProgramSessionTime();
        })
        .catch(async () => {
          const resDownload: any = await downloadFile(url, topicFolder);
          if (!resDownload.error) {
            try {
              const res: number = await unZipDownloadedFile(
                resDownload.data,
                getFileDirectory(),
                topicFolder
              );
              console.log(res);
              if (res === 0) {
                File.removeFile(getFileDirectory(), `${topicFolder}.zip`);
                setLaunchUrl(
                  Capacitor.convertFileSrc(
                    `${getFileDirectory()}${topicFolder}/${activityFolder}/index.html?random=${new Date().getTime()}`
                  )
                );
                addProgramSessionTime();
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            // setLoading(false);
            toastMessage(Messages.DOWNLOAD_ERROR, "danger");
            history.goBack();
          }
        });
    }
  };

  const addProgramSessionTime = async () => {
    if (programId && topicId) {
      const res: any = await ProgramSessionService.instance.getData(
        "activityId",
        id
      );
      if (!res.error) {
        if (res.length > 0) {
          totalTime = res.item(0).totalTime;
          visitCount = res.item(0).visitCount;
          await ProgramSessionService.instance.update(
            "visitCount",
            visitCount + 1,
            id
          );
          if (interval) {
            clearInterval(interval);
            interval = null;
          }
          interval = setInterval(async () => {
            totalTime = totalTime + 5;
            await ProgramSessionService.instance.update(
              "totalTime",
              totalTime,
              id
            );
          }, 5000);
        } else {
          const res: any = await ProgramSessionService.instance.insert({
            programId,
            topicId,
            activityId: id,
            startTime: moment().format("YYYY-MM-DD hh:mm:ss"),
            totalTime,
            visitCount: 1,
          });
          if (!res.error) {
            if (interval) {
              clearInterval(interval);
              interval = null;
            }
            interval = setInterval(async () => {
              totalTime = totalTime + 5;
              await ProgramSessionService.instance.update(
                "totalTime",
                totalTime,
                id
              );
            }, 5000);
          }
        }
      }
    }
  };
  const onReceiveMessage = async (event: any) => {
    eventData = await receiveMessage(event);
    if (eventData) {
      if (eventData.type === "File-Upload") {
        setShowModal(true);
      } else if (eventData.type == "Activity-Completed") {
        let data = eventData.value.data;
        await commitData(data, true);
        markActivityCompleted();
      } else if (eventData.type == "fetchData") {
        let resp: any = null;
        if (isConnectedToInternet()) {
          resp = await fetch(id);
          sessionId = resp.sessionId;
          resp["toolname"] = toolName || "";
          if (resp && !resp.error) {
            OfflineContentService.instance.insertorupdate({
              activityId: id,
              bookmarkData:
                typeof resp.bookmarkData === "string"
                  ? resp.bookmarkData
                  : JSON.stringify(resp.bookmarkData),
              sessionStore:
                typeof resp.sessionStore === "string"
                  ? resp.sessionStore
                  : JSON.stringify(resp.sessionStore),
              isCompleted: false,
            });
          }
        } else {
          const result: any = await OfflineContentService.instance.getData(id);
          if (result && !result.error && result.length > 0) {
            resp = {
              bookmarkData:
                typeof result.item(0).bookmarkData === "string"
                  ? result.item(0).bookmarkData
                  : JSON.parse(result.item(0).bookmarkData),
              sessionStore: JSON.parse(result.item(0).sessionStore),
              sessionId: sessionId,
              toolname: toolName || "",
            };
          } else {
            resp = { toolname: toolName || "" };
          }
        }
        if (resp && !resp.error) {
          sessionId = resp.sessionId;
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: { toolname: toolName || "" },
            },
            "*"
          );
        }
      } else if (eventData.type == "commitData") {
        let data = eventData.value.data;
        await commitData(data);
      } else if (eventData.type == "closeActivity") {
        setShowExitModal(true);
        isExitModal = true;
      } else if (eventData.type == "addTask") {
        addOvanteTodo(eventData.value);
      } else if (eventData.type == "completeTask") {
        changeTodoStatus(eventData.value);
      } else if (eventData.type == "recordVideo") {
        setShowRecordModal(true);
      } else if (eventData.type == "analytics") {
        let data = eventData.value;
        let req = {
          type: data.analyticsType,
          programId: programId,
          topicId: topicId,
          activityId: id,
          key:
            typeof data.analyticsKey === "string"
              ? data.analyticsKey
              : JSON.stringify(data.analyticsKey),
          value:
            typeof data.analyticsValue === "string"
              ? data.analyticsValue
              : JSON.stringify(data.analyticsValue),
        };
        if (isConnectedToInternet()) {
          analytics(req);
        } else {
          const resp: any = await OfflineAnalyticsService.instance.insert(req);
          if (resp && !resp.error) {
            SyncService.instance.insert({
              entityId: resp,
              entityName: "analytics",
            });
          }
        }
      } else if (eventData.type == "addCoins") {
        const { coins } = eventData.value;
        if (isConnectedToInternet()) {
          await addCoins({
            coins,
          });
        } else {
          const resp: any = await OfflineCoinsService.instance.insert({
            coins,
            type: "add",
          });
          if (resp && !resp.error) {
            SyncService.instance.insert({
              entityId: resp,
              entityName: "coins",
            });
          }
        }
        playLottieAnimation(RewardAnimationData);
        setTimeout(() => {
          stopLottieAnimation();
        }, 3000);
      } else if (eventData.type == "reduceCoins") {
        const { coins } = eventData.value;
        await reduceCoins({
          coins,
        });
      } else if (eventData.type == "showToast") {
        const { message, variant } = eventData.value;
        toastMessage(message, variant);
      } else if (eventData.type == "openAppPage") {
        if (isConnectedToInternet()) {
          const { redirectObj } = eventData.value;
          history.push({
            pathname: redirectObj.page,
            state: redirectObj.state || {},
          });
        }
      } else if (eventData.type == "downloadCSVFromData") {
        const { data, fileName } = eventData.value;
        toastMessage("Downloading Started...");
        File.writeFile(`${File.externalRootDirectory}Download`, fileName, data)
          .then((_) => {
            console.log("Success ;-)");
            setTimeout(() => {
              toastMessage("File saved to Download folder");
            }, 3000);
          })
          .catch((err) => {
            File.writeExistingFile(
              `${File.externalRootDirectory}Download`,
              fileName,
              data
            )
              .then((_) => {
                console.log("Success ;-)");
                setTimeout(() => {
                  toastMessage("File saved to Download folder");
                }, 3000);
              })
              .catch((err) => {
                console.log("Failure", err);
              });
          });
      } else if (eventData.type == "downloadCSVFromURL") {
        const { data, fileName } = eventData.value;
        await downloadCSVFromURL(data, fileName);
      } else if (eventData.type == "createCreditProfile") {
        const { data } = eventData.value;
        let req = {
          jsonData: typeof data === "string" ? data : JSON.stringify(data),
        };
        let resp: any = await createCreditProfile(req);

        if (!resp.error) {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp.jsonData,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: "",
            },
            "*"
          );
        }
      } else if (eventData.type == "updateCreditProfile") {
        const { data } = eventData.value;
        let req = {
          jsonData: typeof data === "string" ? data : JSON.stringify(data),
        };
        let resp: any = await updateCreditProfile(req);

        if (!resp.error) {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp.jsonData,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: "",
            },
            "*"
          );
        }
      } else if (eventData.type == "getCreditProfile") {
        let resp: any = await getCreditProfile();
        console.log("resp", resp);
        if (!resp.error) {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp.jsonData,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: {},
            },
            "*"
          );
        }
      } else if (eventData.type == "getIsTopicCompleted") {
        await getIsTopicCompleted();
      }
    }
  };

  const downloadCSVFromURL = async (url: string, fileName: string) => {
    console.log("down", ` ${File.externalRootDirectory}Download/${fileName}`);
    try {
      if (Capacitor.getPlatform() == "ios") {
        const fileTransfer: FileTransferObject = FileTransfer.create();
        const path: string = getFileDirectory() + `${fileName}`;
        const res = await fileTransfer.download(url, path);
        // return {
        //   data: res.nativeURL,
        //   message: "Download success",
        // };
      } else {
        const res = await Http.downloadFile({
          url: `${url}`,
          filePath: `${File.externalRootDirectory}Download/${fileName}`,
          // fileDirectory: FilesystemDirectory.Documents,
        });
        console.log("res", res);
        toastMessage("File saved to Download folder");
      }
    } catch (error) {
      console.log("download error", error);
    }
  };

  const onActivityExitConfirm = () => {
    let data = eventData?.value?.data;
    if (data) {
      commitData(data);
    }
    isExitModal = false;
    setShowExitModal(false);
    history.goBack();
  };
  const commitData = async (data: any, isCompleted = false) => {
    let reqObj = {
      activityId: id,
      bookmarkData: data.bookmarkData || {},
      sessionStore: data.sessionStore || {},
      isCompleted: isCompleted,
      sessionId: sessionId,
    };

    const result: any = await OfflineContentService.instance.insertorupdate({
      activityId: id,
      bookmarkData:
        typeof reqObj.bookmarkData === "string"
          ? reqObj.bookmarkData
          : JSON.stringify(reqObj.bookmarkData),
      sessionStore:
        typeof reqObj.sessionStore === "string"
          ? reqObj.sessionStore
          : JSON.stringify(reqObj.sessionStore),
      isCompleted: reqObj.isCompleted,
    });
    let resp: any = null;
    if (isConnectedToInternet()) {
      resp = await commit(reqObj);
    } else {
      if (result && !result.error) {
        SyncService.instance.insert({
          entityId: result,
          entityName: "content",
        });
      }
    }
  };

  const markActivityCompleted = async () => {
    const offlineRes: any =
      await OfflineActivityService.instance.markActivityComplete(id);
    if (isConnectedToInternet()) {
      let reqObj = {
        id: parseInt(id),
        responseNumber: 3,
      };
      const resp: any = await markActivityComplete(reqObj);
      if (!resp.error) {
        await markSessionComplete();
        if (resp.data && resp.data.length > 0) {
          history.push({
            pathname: `/activityrewards`,
            state: {
              data: JSON.stringify(resp.data),
              programId: resp.programId,
              activityTitle: resp.activityTitle,
              pendingActivities: JSON.stringify(resp.pendingActivities),
              programCompleted: resp.programCompleted,
            },
          });
        } else {
          history.push({
            pathname: `/activitycomplete`,
            state: {
              programId: resp.programId,
              activityTitle: resp.activityTitle,
              pendingActivities: JSON.stringify(resp.pendingActivities),
              programCompleted: resp.programCompleted,
            },
          });
        }
      } else if (resp.statusCode && resp.statusCode === 400) {
        await markSessionComplete();
        // history.replace({
        //   pathname: `/tabs/experts`,
        // });
        history.goBack();
      }
    } else {
      await markSessionComplete();

      const outComeRes: any = await OfflineActivityService.instance.getData(id);

      const pendingActivities: any =
        await OfflineActivityService.instance.getPendingActivity(topicId);

      if (outComeRes && !outComeRes.error && outComeRes.length > 0) {
        const item = outComeRes.item(0);
        await SyncService.instance.insert({
          entityId: item.id,
          entityName: "activity",
        });
        if (offlineRes && !offlineRes.error) {
          if (
            offlineRes > 0 &&
            item.outcomes &&
            JSON.parse(item.outcomes).length > 0
          ) {
            history.push({
              pathname: `/activityrewards`,
              state: {
                data: item.outcomes,
                programId: programId,
                activityTitle: JSON.parse(item.activityData).nodeFieldData
                  .title,
                pendingActivities:
                  pendingActivities &&
                  !pendingActivities.error &&
                  pendingActivities.length > 0
                    ? JSON.stringify([
                        JSON.parse(pendingActivities.item(0).activityData),
                      ])
                    : "[]",
              },
            });
          } else {
            history.push({
              pathname: `/activitycomplete`,
              state: {
                programId: programId,
                activityTitle: JSON.parse(item.activityData).nodeFieldData
                  .title,
                pendingActivities:
                  pendingActivities &&
                  !pendingActivities.error &&
                  pendingActivities.length > 0
                    ? JSON.stringify([
                        JSON.parse(pendingActivities.item(0).activityData),
                      ])
                    : "[]",
              },
            });
          }
        }
      }
    }
  };

  const markSessionComplete = async () => {
    clearInterval(interval);
    interval = null;

    await ProgramSessionService.instance.update(
      "completedTime",
      moment().format("YYYY-MM-DD hh:mm:ss"),
      id
    );

    const activityRes: any = await ProgramSessionService.instance.getData(
      "activityId",
      id
    );
    if (!activityRes.error && activityRes.length > 0) {
      let statusReq = {
        progress: [
          {
            programId: activityRes.item(0).programId,
            topicId: activityRes.item(0).topicId,
            activityId: activityRes.item(0).activityId,
            startTime: activityRes.item(0).startTime,
            completedTime: activityRes.item(0).completedTime,
            totalTime: activityRes.item(0).totalTime,
            visitCount: activityRes.item(0).visitCount,
          },
        ],
      };

      const statusRes: any = await programActivityStatus(statusReq);
      if (!statusRes.error) {
        await ProgramSessionService.instance.delete(id);
      }
    }
  };
  const uploadFileToServer = async (fileObj: any) => {
    if (fileObj) {
      const resp: any = await uploadFile(
        fileObj.uri || fileObj.path,
        fileObj.mediaType || fileObj.type,
        fileObj.name || ""
      );
      if (!resp.error) {
        const parsedJson = JSON.parse(resp);
        iframeRef.contentWindow.postMessage(
          {
            type: eventData.cbEvent,
            data: {
              fileId: parsedJson.id,
              file: getFormattedUrl(parsedJson.uri),
            },
          },
          "*"
        );
        setShowModal(false);
      }
    }
  };

  const addOvanteTodo = async (data: any) => {
    let dueDate = data.dueDate ? convertLocalToUTC(data.dueDate) : null;
    let reqObj = {
      title: data.title || "",
      dueDate: dueDate ? dueDate : null,
      machineName: data.machineName,
      activityId: id,
      type: "task",
      isReminder: data.isReminder || false,
    };
    // console.log("ovante", reqObj);
    const offlineRes: any = await OfflineTodoService.instance.insert(reqObj);
    if (isConnectedToInternet()) {
      const resp: any = await addOvanteTask(reqObj);
      if (!resp.error) {
        if (data.isReminder && dueDate) {
          localnotificationService.schedule({
            id: resp.id,
            title: "Reminder",
            text: data.title,
            autoClear: true,
            data: { type: "reminder", id },
            trigger: {
              at: new Date(data.dueDate),
            },
            priority: 2,
          });
        }
        // toastMessage(Messages.ADD_TODO_SUCCESS, "success");
        iframeRef.contentWindow.postMessage(
          { type: eventData.cbEvent, data: true },
          "*"
        );
      } else {
        toastMessage(Messages.SERVER_ERROR, "primary");
        iframeRef.contentWindow.postMessage(
          { type: eventData.cbEvent, data: false },
          "*"
        );
      }
    } else {
      if (offlineRes && !offlineRes.error) {
        SyncService.instance.insert({
          entityId: offlineRes,
          entityName: "todo",
        });
      }
    }
  };

  const changeTodoStatus = async (data: any) => {
    const offlinetodo: any = await OfflineTodoService.instance.getData(
      data.machineName
    );
    if (offlinetodo && !offlinetodo.error && offlinetodo.length > 0) {
      OfflineTodoService.instance.changeStatus(data.machineName);
    }
    const resp: any = await changeOvanteTaskStatus({
      machineName: data.machineName,
      isCompleted: true,
    });
    if (!resp.error) {
      localnotificationService.cancel(resp.id);
      // toastMessage(Messages.COMPLETED_TODO_SUCCESS, "success");
      iframeRef.contentWindow.postMessage(
        { type: eventData.cbEvent, data: true },
        "*"
      );
      if (offlinetodo && !offlinetodo.error && offlinetodo.length > 0) {
        for (let i = 0; i < offlinetodo.length; i++) {
          await SyncService.instance.changeStatus(
            offlinetodo.item(i).id,
            "todo"
          );
        }
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
      iframeRef.contentWindow.postMessage(
        { type: eventData.cbEvent, data: false },
        "*"
      );

      if (offlinetodo && !offlinetodo.error && offlinetodo.length > 0) {
        for (let i = 0; i < offlinetodo.length; i++) {
          const syncData: any = await SyncService.instance.isExist(
            offlinetodo.item(i).id,
            "todo"
          );
          if (syncData && !syncData.error && syncData.length == 0) {
            await SyncService.instance.insert({
              entityId: offlinetodo.item(i).id,
              entityName: "todo",
            });
          }
        }
      }
    }
  };

  const getIsTopicCompleted = async () => {
    const resp: any = await getTopicCompleted();
    if (!resp.error) {
      iframeRef.contentWindow.postMessage(
        { type: eventData.cbEvent, data: resp },
        "*"
      );
    }
  };

  return (
    <IonPage className="page-learning">
      {/* <RegisterHeader pageName={"Topic"} backBtn={true}></RegisterHeader> */}
      <IonContent>
        {launchUrl !== "" ? (
          <WebView
            getRef={(ref: any) => {
              iframeRef = ref;
            }}
            launchUrl={launchUrl}
            onReceiveMessage={onReceiveMessage}
          />
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "100%",
            }}
          >
            <Loader></Loader>
            <br />
            <LanguageAppText
              tag={"span"}
              value={"Loading, Please Wait..."}
              textKey={"loading_please_wait"}
            />
          </div>
        )}
        {showModal && (
          <ModalPopUp
            showModal={showModal}
            onCloseModal={() => {
              setShowModal(false);
            }}
          >
            <UploadMedia
              getMedia={(fileObj: any) => {
                uploadFileToServer(fileObj);
              }}
            />
          </ModalPopUp>
        )}
        {showRecordModal && (
          <ModalPopUp
            showModal={showRecordModal}
            onCloseModal={() => {
              setShowRecordModal(false);
            }}
          >
            <UploadVideo
              onClose={() => {
                setShowRecordModal(false);
              }}
              onSuccess={(resObj: any) => {
                iframeRef.contentWindow.postMessage(
                  { type: eventData.cbEvent, data: resObj },
                  "*"
                );
                setShowRecordModal(false);
              }}
            />
          </ModalPopUp>
        )}
        {showExitModal && (
          <Popup
            customClass="program-intro-popup"
            onClose={() => {
              setShowExitModal(false);
              isExitModal = false;
            }}
          >
            <div className="program-popup-container">
              <IonImg src={ActivityExit} className="success-icon mb-10" />
              <LanguageAppText
                tag={"div"}
                className="program-popup-title text-deep-black font-bold mb-5"
                value={"Exit topic"}
                textKey={"exit_topic"}
              />
              <LanguageAppText
                tag={"div"}
                className="program-popup-description text-light-black font-medium mb-20"
                value={
                  "Please confirm that you want to exit the current topic. You can always pick back up later!"
                }
                textKey={"confirm_exit_topic"}
              />
              <ButtonComponent
                name="Confirm"
                onClick={onActivityExitConfirm}
                customClass="program-popup-btn mb-10"
                textKey={"confirm"}
              />
              <div
                onClick={() => {
                  setShowExitModal(false);
                  isExitModal = false;
                }}
                className=""
              >
                <LanguageAppText
                  tag={"span"}
                  className="program-popup__no-change text-light-blue font-medium"
                  value={"No, I don’t want to exit"}
                  textKey={"dont_exit"}
                />
              </div>
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};
export default Activity;
