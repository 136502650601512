import SQLiteService from "../sqlite.service";

const TABLE_NAME = "analytics";
const COLUMN_ID = "id";
const COLUMN_TYPE = "type";
const COLUMN_PROGRAM_ID = "programId";
const COLUMN_TOPIC_ID = "topicId";
const COLUMN_ACTIVITY_ID = "activityId";
const COLUMN_KEY = "key";
const COLUMN_VALUE = "value";
const COLUMN_FILE = "file";
const COLUMN_CREATED_AT = "createdAt";
const COLUMN_UPDATED_AT = "updatedAt";

const TABLE_CREATE =
  "create table if not exists " +
  TABLE_NAME +
  "(" +
  COLUMN_ID +
  " INTEGER PRIMARY KEY AUTOINCREMENT, " +
  COLUMN_TYPE +
  " TEXT, " +
  COLUMN_PROGRAM_ID +
  " int," +
  COLUMN_TOPIC_ID +
  " int," +
  COLUMN_ACTIVITY_ID +
  " int," +
  COLUMN_KEY +
  " TEXT, " +
  COLUMN_VALUE +
  " TEXT, " +
  COLUMN_FILE +
  " TEXT, " +
  COLUMN_CREATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')) ," +
  COLUMN_UPDATED_AT +
  " TEXT DEFAULT (strftime('%s','now','localtime')))";

let _singleton = true;
let _instance: OfflineAnalyticsService;
let sqliteService: SQLiteService = SQLiteService.instance;
class OfflineAnalyticsService {
  constructor() {
    if (_singleton) {
      throw new SyntaxError(
        "This is a singleton class. Please use OfflineAnalyticsService.instance instead!"
      );
    }
  }

  static get instance() {
    if (!_instance) {
      _singleton = false;
      _instance = new OfflineAnalyticsService();
      _singleton = true;
      sqliteService.createTable(TABLE_CREATE);
    }
    return _instance;
  }

  public async insert(value: any) {
    let result;

    let values = "";
    values += "'" + value.type;
    values += "'," + value.programId;
    values += "," + value.topicId;
    values += "," + value.activityId;
    values += ",'" + value.key;
    values += "','" + value.value + "'";

    let col =
      COLUMN_TYPE +
      ", " +
      COLUMN_PROGRAM_ID +
      ", " +
      COLUMN_TOPIC_ID +
      ", " +
      COLUMN_ACTIVITY_ID +
      ", " +
      COLUMN_KEY +
      ", " +
      COLUMN_VALUE;
    result = await sqliteService.insertWithCustom(TABLE_NAME, col, values);
    return result;
  }
}

export default OfflineAnalyticsService;
