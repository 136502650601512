import { Capacitor, Plugins } from "@capacitor/core";
import { CaptureVideoOptions } from "@awesome-cordova-plugins/media-capture";
import {
  IonButton,
  IonContent,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
  IonTextarea,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import TitlePolygonIcon from "../../assets/images/icons/icon-polygon-audio-record.svg";
import PlayAudio from "../../assets/images/icons/icon-polygon-orange.svg";
import MicIconOrange from "../../assets/images/icons/mic-icon-orange.svg";
import MicIcon from "../../assets/images/icons/mic-icon.svg";
import StopRecorder from "../../assets/images/icons/stop-recorder.svg";
import ShareIcon from "../../assets/images/icons/story-share-btn.svg";
import VideoCameraIcon from "../../assets/images/icons/video-camera-icon.svg";
import AudioRecorder from "../../components/audiorecorder";
import ButtonComponent from "../../components/common/button-component";
import InputText from "../../components/common/inputtext";
import Loader from "../../components/common/loader";
import RegisterHeader from "../../components/common/register-header";
import CommentCard from "../../components/community/commentcard";
import EmptyCommentList from "../../components/community/emptycommentlist";
import VideoPlayer from "../../components/videoplayer";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import {
  addStoryComment,
  addStoryMediaComment,
  deleteStoryComment,
  editStoryComment,
  getStoryComments,
  getStoryDetails,
  getWatchCommunityVideo,
  makeStoryCommetLike,
  makeStoryLike,
} from "../../services/community.service";
import { uploadFile } from "../../services/filetransfer.service";
import { getFormattedUrl, isOSBelowOREqual } from "../../utils/common.helper";
import { videoRecorder } from "../../utils/media.helper";
import RecorderVideo from "../recordedvideo";
import "./style.scss";
import Back from "../../assets/images/icons/back.svg";
import ModalPopUp from "../../components/common/modalpopup";
import {
  checkPermission,
  requestPermission,
} from "../../utils/permission.helper";
import { AndroidPermissions } from "@ionic-native/android-permissions";
import LanguageAppText from "../../components/languageapptext";

const { ZipExtractPlugin } = Plugins;
let isFullVideo = false,
  isCommentVideo: any;
const CommunityStory: React.FC = () => {
  let { id }: any = useParams();
  const { toastMessage, userDetails, language, descEnFile, descFile }: any =
    useContext(AppContext);
  const [textValue, setTextValue] = useState("");
  let placeholderText =
    descFile["leave_a_comment".toLowerCase()] ||
    descEnFile["leave_a_comment".toLowerCase()] ||
    "Leave a comment...";
  const [placeholder, setPlaceholder]: any = useState(placeholderText);
  const [showAudioRecord, setShowAudioRecord] = useState(false);
  const [comments, setComments]: any = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [storyDetails, setStoryDetails]: any = useState(null);
  const [likeCount, setLikeCount] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lastCommentId, setLastCommentId] = useState(0);
  const [lastCommentRecieved, setLastCommentRecieved] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(true);
  const [isCommentPost, setIsCommentPost] = useState(false);
  const [videoFileUri, setVideoFileUri] = useState("");
  const [thumbUri, setThumbUri] = useState("");
  const [editCommentObj, setEditCommentObj]: any = useState(null);
  const [commentVideo, setCommentVideo]: any = useState(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const history: any = useHistory();

  useEffect(() => {
    fetchData();
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isFullVideo = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isFullVideo) {
        isFullVideo = false;
        setIsFullScreen(false);
        if (isCommentVideo) {
          isCommentVideo = null;
          setCommentVideo(null);
        }
      } else {
        history.goBack();
      }
    });
  };

  const fetchData = async () => {
    const response: any = await getStoryDetails(id);
    if (!response.error) {
      setStoryDetails(response);
      setLikeCount(response.likeCount);
    }
    setLoading(false);
    await getComments();
  };

  const getComments = async (event?: any) => {
    if (!isFirstLoad && (lastCommentRecieved || isCommentLoading)) return;
    setIsCommentLoading(true);
    const commentResp: any = await getStoryComments({
      storyId: parseInt(id),
      lastCommentId: lastCommentId,
    });
    if (!commentResp.error) {
      if (event) {
        event.target.complete();
      }
      if (commentResp.length > 0) {
        setLastCommentId(commentResp[commentResp.length - 1].id);
        setComments(comments.concat(commentResp));
      } else {
        if (event) {
          event.target.disabled = true;
        }
        setLastCommentRecieved(true);
      }
    }
    // console.log(commentResp[commentResp.length - 1].id, lastCommentId)

    setIsCommentLoading(false);
    setIsFirstLoad(false);
  };

  const onChangeText = (text: string) => {
    setTextValue(text);
  };

  const storyLike = async () => {
    const response: any = await makeStoryLike({
      id: parseInt(id),
    });

    if (!response.error) {
      if (response == "liked") {
        let likes = likeCount ? likeCount + 1 : 1;
        setLikeCount(likes);
      } else {
        let likes = likeCount && likeCount > 0 ? likeCount - 1 : 0;
        setLikeCount(likes);
      }
      return true;
    }
    return false;
  };

  const postTextComment = async () => {
    setIsCommentPost(true);
    const response: any = await addStoryComment({
      entityId: parseInt(id),
      text: textValue,
    });
    if (!response.error) {
      response.user.profile = {
        profilePic: userDetails.profilePic,
      };
      toastMessage(Messages.COMMENT_SUBMIT, "success");
      setComments([response, ...comments]);
      setIsCommentPost(false);
      setTextValue("");
      return true;
    }
    toastMessage(Messages.SERVER_ERROR, "primary");
    setIsCommentPost(false);
    return false;
  };

  const getWatchedCommunityVideo = () => {
    if (!isVideoWatched) {
      setIsVideoWatched(true);
      getWatchCommunityVideo(parseInt(id));
    }
  };

  const hasPermission = async (permission: string) => {
    let hasPermission = false;

    hasPermission = await checkPermission(
      permission
      // AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
    );

    if (!hasPermission) {
      hasPermission = await requestPermission(
        permission
        // AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
      );
    }

    return hasPermission;
  };

  const onRecordVideo = async () => {
    let isAllowed = false;
    if (await isOSBelowOREqual(12)) {
      isAllowed = await hasPermission(
        AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
      );
    } else {
      isAllowed = true;
    }
    if (isAllowed) {
      setLoading(true);
      let options: CaptureVideoOptions = { duration: 180 };
      const videoData = await videoRecorder(options);
      console.log("video-----", videoData);
      if (videoData.compressed) {
        setVideoFileUri(videoData.compressed);
      }
      if (videoData.thumbnail) {
        setThumbUri(videoData.thumbnail);
      }
      setLoading(false);
    }
  };

  const onAudioRecord = async () => {
    if (Capacitor.getPlatform() == "android") {
      const isAllowed = await hasPermission(
        AndroidPermissions.PERMISSION.RECORD_AUDIO
      );
      let isMediaAllowed = false;
      if (await isOSBelowOREqual(12)) {
        isMediaAllowed = await hasPermission(
          AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
        );
      } else {
        isMediaAllowed = true;
      }
      if (isAllowed && isMediaAllowed) {
        setShowAudioRecord(true);
      }
    } else {
      const isAllowed = await ZipExtractPlugin.requestMicrophonePermission();
      if (isAllowed.result) {
        setShowAudioRecord(true);
      }
    }
  };
  const redoPost = () => {
    setVideoFileUri("");
    setThumbUri("");
  };

  const postVideoComment = async () => {
    setLoading(true);

    const videoRes: any = await uploadFile(
      "file://" + videoFileUri,
      "video/mp4",
      ""
    );
    const thumbRes: any = await uploadFile(
      "file://" + thumbUri,
      "image/jpeg",
      ""
    );
    if (!videoRes.error && !thumbRes.error) {
      const commentObj = {
        commentType: "video",
        entityId: parseInt(id),
        fileId: JSON.parse(videoRes).id,
        thumbnailId: JSON.parse(thumbRes).id,
      };
      const commentRes: any = await addStoryMediaComment(commentObj);
      if (!commentRes.error) {
        commentRes.user.profile = {
          profilePic: userDetails.profilePic,
        };
        setVideoFileUri("");
        setThumbUri("");
        toastMessage(Messages.COMMENT_SUBMIT, "success");

        setComments([commentRes, ...comments]);
      } else {
        toastMessage(Messages.SERVER_ERROR, "primary");
      }
    } else {
      toastMessage(Messages.FILE_UPLOAD_ERROR, "primary");
    }
    setLoading(false);
  };

  const postAudioComment = async (audioUri: any) => {
    setLoading(true);
    const response: any = await uploadFile(
      "file://" + audioUri,
      "audio/mp3",
      ""
    );
    if (!response.error) {
      const commentObj = {
        commentType: "audio",
        entityId: parseInt(id),
        fileId: JSON.parse(response).id,
      };
      const commentRes: any = await addStoryMediaComment(commentObj);
      if (!commentRes.error) {
        commentRes.user.profile = {
          profilePic: userDetails.profilePic,
        };
        toastMessage(Messages.COMMENT_SUBMIT, "success");
        setShowAudioRecord(false);
        console.log(commentRes);
        setComments([commentRes, ...comments]);
      } else {
        toastMessage(Messages.SERVER_ERROR, "primary");
      }
    } else {
      toastMessage(Messages.FILE_UPLOAD_ERROR, "primary");
    }
    setLoading(false);
  };

  const handleCommentEditClick = (id: number, text: string) => {
    setEditCommentObj({ id, text });
    setTextValue(text);
  };

  const editComment = async () => {
    setIsCommentPost(true);
    const response: any = await editStoryComment({
      entityId: editCommentObj.id,
      text: textValue,
    });
    if (!response.error) {
      response.user.profile = {
        profilePic: userDetails.profilePic,
      };
      toastMessage(Messages.COMMENT_EDIT, "success");

      const tempComments: any = comments.map((item: any) => {
        if (item.id == response.id) {
          return response;
        }
        return item;
      });
      setComments(tempComments);
      setEditCommentObj(null);
      setTextValue("");
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
    setIsCommentPost(false);
  };

  const handleCommentLikeClick = async (id: number) => {
    return await storyCommentLike(id);
  };

  const storyCommentLike = async (id: number) => {
    const response: any = await makeStoryCommetLike({
      id: id,
    });
    if (!response.error) {
      const tempComment = comments;
      let obj: any = tempComment.find((item: any) => {
        return item.id === id;
      });
      if (obj) {
        if (response == "liked") {
          obj.likeCount = obj.likeCount ? obj.likeCount + 1 : 1;
          obj.isLiked = true;
        } else {
          obj.likeCount =
            obj.likeCount && obj.likeCount > 0 ? obj.likeCount - 1 : 0;
          obj.isLiked = false;
        }

        setComments(() => [...tempComment]);
      }
      return true;
    }
    return false;
  };

  const handleCommentDeleteClick = async (id: number) => {
    const resp: any = await deleteStoryComment(id);
    if (!resp.error) {
      const tempComments = comments.filter((item: any) => id != item.id);
      setComments(tempComments);
      toastMessage(Messages.COMMENT_DELETE, "success");
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };
  return (
    <>
      <IonPage
        className="page-community-story"
        style={isFullScreen ? { backgroundColor: "black" } : {}}
      >
        {!isFullScreen && videoFileUri == "" && !commentVideo && (
          <div className="page-community-story__header">
            <RegisterHeader
              pageName="Community Story"
              backBtn={true}
              textKey={"community_story"}
            ></RegisterHeader>
            {/* <IonButton className="page-community__share-btn ion-button__transparent-with-icon">
              <IonImg src={ShareIcon} className="share-btn" />
            </IonButton> */}
          </div>
        )}
        <IonContent
          fullscreen={true}
          className="page-content pb-0"
          style={isFullScreen ? { maxWidth: "none" } : {}}
        >
          {loading ? (
            <Loader customClass="loader-page-center" />
          ) : videoFileUri !== "" ? (
            <RecorderVideo
              screenType="submitComment"
              url={Capacitor.convertFileSrc("file://" + videoFileUri)}
              thumbnail={Capacitor.convertFileSrc("file://" + thumbUri)}
              onRedo={redoPost}
              onPost={postVideoComment}
            />
          ) : (
            <>
              {!isFullScreen && (
                <div className="mb-10 page-content__title">
                  {storyDetails.userName
                    ? `${storyDetails.userName}'s Story`
                    : storyDetails.title}
                </div>
              )}
              <div className="community-story__container">
                <div className="community-story__video-container stretch-container mb-40">
                  <VideoPlayer
                    url={getFormattedUrl(storyDetails.video.uri)}
                    thumbnail={getFormattedUrl(storyDetails.thumbnail.uri)}
                    isLiked={storyDetails.isLiked}
                    likeCount={likeCount}
                    isFullScreen={isFullScreen}
                    onFullScreenChange={(isFullScreen: boolean) => {
                      setIsFullScreen(isFullScreen);
                      isFullVideo = isFullScreen;
                    }}
                    onLikePress={storyLike}
                    onVideoPlay={getWatchedCommunityVideo}
                    disableOrientation={commentVideo ? true : false}
                  />
                </div>
                {!isFullScreen && (
                  <div
                    className={`community-story__comments ${
                      !comments || (comments && comments.length < 0)
                        ? "story-comment__add-padding"
                        : ""
                    }`}
                  >
                    {isCommentLoading && isFirstLoad ? (
                      <Loader />
                    ) : comments && comments.length > 0 ? (
                      <>
                        <LanguageAppText
                          tag={"div"}
                          className="text-comment text-blue font-medium mb-5"
                          value={"Comments"}
                          textKey={"comments"}
                        />
                        <IonList className="community-story__comments-list">
                          {comments.map((item: any, index: number) => {
                            console.log(userDetails, item);

                            return (
                              <CommentCard
                                key={index}
                                data={item}
                                showDelete={userDetails.id === item.user.id}
                                showEdit={
                                  item.commentType === "text" &&
                                  userDetails.id === item.user.id
                                }
                                onEdit={(id: number, commentText: string) => {
                                  handleCommentEditClick(id, commentText);
                                }}
                                onVidoePlay={(
                                  videoUrl: string,
                                  thumbUrl: string
                                ) => {
                                  setCommentVideo({ videoUrl, thumbUrl });
                                  isCommentVideo = { videoUrl, thumbUrl };
                                  isFullVideo = true;
                                }}
                                onLikePress={handleCommentLikeClick}
                                onDeletePress={handleCommentDeleteClick}
                              />
                            );
                          })}
                        </IonList>

                        <IonInfiniteScroll
                          threshold="100px"
                          onIonInfinite={(event) => {
                            getComments(event);
                          }}
                          disabled={lastCommentRecieved}
                        >
                          <IonInfiniteScrollContent
                            loadingSpinner="circles"
                            loadingText="Loading more data..."
                          ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                      </>
                    ) : (
                      <EmptyCommentList />
                    )}
                  </div>
                )}
              </div>
              {!isFullScreen && (
                <div className="page-content__add-comment stretch-container bg-white">
                  {showAudioRecord ? (
                    <AudioRecorder
                      onBack={() => {
                        setShowAudioRecord(false);
                      }}
                      onPost={(file: any) => {
                        postAudioComment(file);
                      }}
                    />
                  ) : (
                    <div className="add-comment">
                      <IonTextarea
                        name="todo"
                        value={textValue}
                        placeholder={placeholder}
                        // placeholderKey={placeholder.key}
                        // multiple={true}
                        autoGrow
                        rows={1}
                        cols={3}
                        onFocus={() => {
                          if (textValue.length == 0) {
                            let placeholderText =
                              descFile["start_typing".toLowerCase()] ||
                              descEnFile["start_typing".toLowerCase()] ||
                              "Start typing...";
                            setPlaceholder(placeholderText);
                          }
                        }}
                        onBlur={() => {
                          if (textValue.length == 0) {
                            let placeholderText =
                              descFile["leave_a_comment".toLowerCase()] ||
                              descEnFile["leave_a_comment".toLowerCase()] ||
                              "Leave a comment...";
                            setPlaceholder(placeholderText);
                          }
                        }}
                        onIonChange={(e: any) => {
                          onChangeText(e.detail.value);
                        }}
                      ></IonTextarea>
                      <div className="comment-btns">
                        {textValue.trim().length > 0 ? (
                          isCommentPost ? (
                            <Loader />
                          ) : (
                            <ButtonComponent
                              name="Post"
                              textKey={"post"}
                              customClass="post-btn"
                              onClick={() => {
                                editCommentObj
                                  ? editComment()
                                  : postTextComment();
                              }}
                            ></ButtonComponent>
                          )
                        ) : (
                          <>
                            <IonImg
                              src={MicIcon}
                              className="comment-mic mr-10"
                              onClick={() => {
                                onAudioRecord();
                              }}
                            />
                            <IonImg
                              src={VideoCameraIcon}
                              className="comment-video-camera ml-10"
                              onClick={() => {
                                onRecordVideo();
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {commentVideo && (
            <ModalPopUp
              showModal={true}
              onCloseModal={() => {
                setCommentVideo(null);
                isCommentVideo = null;
                isFullVideo = false;
              }}
            >
              <div className="page-reward-video">
                <IonButton
                  className="ion-button__back-btn"
                  onClick={() => {
                    setCommentVideo(null);
                    isCommentVideo = null;
                    isFullVideo = false;
                  }}
                >
                  <IonImg src={Back} className="back-icon" />
                </IonButton>
                <VideoPlayer
                  url={getFormattedUrl(commentVideo.videoUrl)}
                  thumbnail={getFormattedUrl(commentVideo.thumbUrl)}
                  isFullScreen
                  hideFullScreen={true}
                />
              </div>
            </ModalPopUp>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default CommunityStory;
