import HttpService, { HttpMethod } from "./http.service";

export const getProgramById = async (id: number) => {
  const response = await HttpService.execute({
    url: `/programs/details/${id}`,
  });
  return response;
};

export const getTopicById = async (id: number) => {
  const response = await HttpService.execute({
    url: `/programs/topics/details/${id}`,
  });
  return response;
};

export const markActivityComplete = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/programs/activity/mark-complete`,
    data: reqObj,
  });
  return response;
};

export const getProgramList = async () => {
  const response: any = await HttpService.execute({
    url: `/programs/experts`,
  });
  return response;
};

export const getProgramCompleted = async () => {
  const response = await HttpService.execute({
    url: `/programs/experts/completed`,
  });
  return response;
};

export const markProgramSelect = async (req: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/programs/experts/select`,
    data: req,
  });
  return response;
};

export const getUserCurrentProgram = async () => {
  const response = await HttpService.execute({
    url: `/programs/user/current-program`,
  });
  return response;
};

export const getActivityById = async (id: number) => {
  const response = await HttpService.execute({
    url: `/programs/activity/details/${id}`,
  });
  return response;
};

export const programActivityStatus = async (req: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/programs/activity-status`,
    data: req,
  });
  return response;
};

export const getFirstEarnedUsers = async () => {
  const response = await HttpService.execute({
    url: `/programs/first-expert-earned-users`,
  });
  return response;
};

export const getOutcomes = async (id: any) => {
  const response = await HttpService.execute({
    url: `/programs/outcome-of-topic/${id}`,
  });
  return response;
};

export const getTopicCompleted = async () => {
  const response = await HttpService.execute({
    url: `/programs/is-topic-completed`,
  });
  return response;
};

export const getRecommendedProgramList = async () => {
  const response: any = await HttpService.execute({
    url: `/programs/experts/getRecommendedPrograms`,
  });
  return response;
};
