import { IonImg, IonThumbnail } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import DefaultHomeCardImg from "../../assets/images/global-default_new.jpeg";
import { AppContext } from "../../context/app.context";
import { formatStringToJSON } from "../../utils/common.helper";
import LanguageAppText from "../languageapptext";
import "./style.scss";

interface Props {
  cardName?: string;
  cardKey?: string;
  topicText?: string;
  topicTextKey?: string;
  customClass?: string;
  imgPath?: string;
  storyCard?: boolean;
  storyText?: string;
}

const HomeCard: React.FC<Props> = ({
  cardName,
  cardKey,
  topicText,
  customClass,
  imgPath,
  storyCard,
  storyText,
  topicTextKey,
}) => {
  const { language }: any = useContext(AppContext);
  const [mediaPath, setMediaPath] = useState(imgPath);
  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);
  let tagValue: any = cardName;
  if (typeof cardName === "string") {
    tagValue = formatStringToJSON(cardName);
  }
  return (
    <div className={`home-card__container mt-40 ${customClass || ""}`}>
      {typeof tagValue === "string" ? (
        <LanguageAppText
          tag={"div"}
          className="home-card__name ml-10 text-white bg-orange font-medium"
          value={tagValue}
          textKey={cardKey}
        />
      ) : (
        <div className="home-card__name ml-10 text-white bg-orange font-medium">
          {tagValue[language] || tagValue["en"]}
        </div>
      )}
      {/* <div className="home-card__name ml-10 text-white bg-orange font-medium">
        {typeof tagValue === "string"
          ? tagValue
          : tagValue[language] || tagValue["en"]}
      </div> */}
      <div
        className={`home-card__image-container ${
          !storyCard ? "home-card-without-story" : ""
        }`}
      >
        <IonThumbnail
          className={`${storyCard ? "card-image-story" : "card-image"}`}
        >
          <img
            className="card-image-border-radius"
            src={mediaPath || DefaultHomeCardImg}
          />
        </IonThumbnail>
        {/* <IonImg
          src={mediaPath || DefaultHomeCardImg}
          className={`${storyCard ? "card-image-story" : "card-image"}`}
        /> */}
        {!storyCard && (
          // <div className="home-card__topic-text text-white font-medium">
          //   {topicText}
          // </div>
          <LanguageAppText
            tag={"div"}
            className="home-card__topic-text text-white font-medium"
            value={topicText}
            textKey={topicTextKey}
          />
        )}
        {storyCard && (
          <div className="home-card__story-text bg-green text-white font-medium">
            {storyText}
          </div>
        )}
      </div>
    </div>
  );
};
export default HomeCard;
