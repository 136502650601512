import React, { useEffect, useRef, useState } from "react";
import { IonButton, useIonRouter } from "@ionic/react";
import { serializePostMessageData } from "../../utils/common.helper";
import { useHistory } from "react-router";

interface Props {
  launchUrl: string;
  getRef?: (ref: any) => void;
  onReceiveMessage?: (msg: any) => void;
}
const WebView = (props: Props) => {
  let iframeRef: any;
  const history = useHistory();
  const [messageData, setMessageData] = useState();
  const ionRouter = useIonRouter();
  useEffect(() => {
    window.addEventListener("message", onReceiveMessage);
    // document.addEventListener("ionBackButton", (ev: any) => {
    //   // ev.detail.register(5, () => {
    //   //   console.log("back", ionRouter.canGoBack());
    //   //   if (ionRouter.canGoBack()) {
    //   //     ionRouter.goBack();
    //   //   }
    //   // });

    //   ev.detail.register(10, (processNextHandler: any) => {
    //     // window.history.back();
    //     history.goBack();
    //     processNextHandler();
    //   });
    // });
    return () => {
      window.removeEventListener("message", onReceiveMessage);
    };
  }, []);

  const iframeLoaded = async () => {
    console.log("Iframe loaded");
  };

  const onReceiveMessage = (event: any) => {
    // setMessageData(event.data);
    props.onReceiveMessage && props.onReceiveMessage(event.data);
  };

  const sendMessage = () => {
    const serializedData = serializePostMessageData("Message from Parent");
    iframeRef.contentWindow.postMessage(serializedData, "*");
  };

  return (
    <iframe
      ref={(el) => {
        iframeRef = el;
        props.getRef && props.getRef(el);
        if (iframeRef) {
          iframeRef.addEventListener("load", iframeLoaded);
        }
      }}
      src={props.launchUrl}
      style={{ width: "100%", height: "100%" }}
    />
  );
};
export default WebView;
